import { RegistrableApp, ObjectType } from "qiankun"
import { env, TOKEN, CID } from "./constants"

/**子应用访问地址映射 */
const appEnvHostMap = {
  salaryConfigManager: {  // 薪资管理
    local: "localhost:30001",
    dev: "devsalarymanager.viphrm.com",
    pre: "presalarymanager.viphrm.com",
    pro: "salaryslipmanager.viphrm.com"
  },
  paySalaryManager: {    // 发薪管理
    local: "localhost:30002",
    dev: "devsalaryweizhi.viphrm.com",
    pre: "testsalaryweizhi.viphrm.com",
    pro: "salaryweizhi.viphrm.com"
  },
  eContractComapny: {    // 电子合同
    local: "localhost:10014",
    dev: "devecontractcompany.viphrm.com",
    pre: "testecontractcompany.viphrm.com",
    pro: "econtractcompany.viphrm.com"
  }
}

/**
 * 获取子应用Host地址
 * 
 * @param key 
 * @returns 
 */
const getAppHost = (key: string): string => {
  if (env === "pro") {
    return appEnvHostMap[key][env]
  }
  return appEnvHostMap[key]["pre"]
}


/**
 * 子应用激活规则
 * 
 * @param routerPrefix 
 * @returns 
 */
const genActiveRule = (routerPrefix: string) => {
  return (location: any) => {
    if (location && location.pathname) {
      return location.pathname.startsWith(routerPrefix)
    }
    return false
  }
}


/**获取子应用对应的路由前缀和图标 */
export const getMicroAppData = (appCode: string): { routerPrefix: string, icon: string } => {
  const appDataMap = {
    30001: {
      routerPrefix: "/salaryConfigManager",
      icon: "icon-xinziguanliyixuanzhong"
    },
    30002: {
      routerPrefix: "/paySalaryManager",
      icon: "icon-fafangyixuanzhong"
    },
    10014: {
      routerPrefix: "/eContractComapny",
      icon: "icon-hetongguanli1"
    }
  }
  return appDataMap[appCode]
}


/**子应用code集合 */
export const microAppCodes = ["30001", "30002", "10014"];


/**子应用配置 */
export const microApps: RegistrableApp<ObjectType>[] = [
  {
    name: "viphrm-salary-manager", // 薪资管理
    entry: `//${getAppHost("salaryConfigManager")}`,
    container: "#childView",
    activeRule: genActiveRule("/shr/salaryConfigManager"),
    props: {
      sourceAppCode: 30001,
      token: TOKEN,
      isEnterprise: true,
      preRouterUrl: "/shr/salaryConfigManager",
      cid: CID
    }
  },
  {
    name: "salary-cpay-web-moss", // 发放管理
    entry: `//${getAppHost("paySalaryManager")}`,
    container: "#childView",
    activeRule: genActiveRule("/shr/paySalaryManager"),
    props: {
      sourceAppCode: 30002,
      token: TOKEN,
      isEnterprise: true,
      preRouterUrl: "/shr/paySalaryManager",
      cid: CID
    }
  },
  {
    name: "contract-client", // 电子合同
    entry: `//${getAppHost("eContractComapny")}`,
    container: "#childView",
    activeRule: genActiveRule("/shr/eContractComapny"),
    props: {
      token: TOKEN,
      preRouterUrl: "/shr/eContractComapny",
      cid: CID
    }
  }
]
