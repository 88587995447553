import React, { useCallback, useEffect, useState } from "react";
import { MenuType } from "common/types";
import { Layout, Menu, Row } from "antd";
import VhIcon from "../vh-icon/VhIcon";
import { RouteComponentProps, withRouter } from "dva/router";
import "./aVhMenu.scss";

interface PropsOwn {
  /** 公司logo */
  companyLogo: string;
  /** 菜单列表 */
  menu: MenuType[];
  /** 标题 */
  title: string;
  /** 展开/收缩状态 */
  collapse: boolean;
  /** 公司cid */
  companyCid: string;
  openKeysList: any[];
  realName: string;
  name: string;
}

type AllProps = PropsOwn & RouteComponentProps;

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

const VhMenu = (props: AllProps) => {

  const { menu, collapse, openKeysList, location, history } = props;

  const [menuList, setMenuList] = useState<MenuType[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  /**
   * 获取菜单中包含当前路由的所有数组，但只取最后一个。
   * menu是通过接口得到，所以会渲染一次，根路由进来时返回结果为null。
   * 路由变更，pathname就会变更，所以会渲染多次。
   */
  const getCurrentRouteObj = useCallback((): MenuType | null => {
    const path = location.pathname;
    const matchUrlArr: MenuType[] = menuList.filter((ele: MenuType) => path.startsWith(`${ele.visitURL}`));
    return matchUrlArr.length ? matchUrlArr[matchUrlArr.length - 1] : null;
  }, [location.pathname, menuList]);

  useEffect(() => {
    setOpenKeys(openKeysList)
  }, [openKeysList])

  useEffect(() => {
    if (menu?.length) {
      // 将menu数组平铺为一维数组
      const arr = menu.reduce((res: any[], current: any) => {
        if (current.children?.length) {
          res = res.concat(current.children)
        } else {
          res.push(current)
        }
        return res
      }, [])
      setMenuList(arr);
    }
  }, [menu])

  useEffect(() => {
    const currentRoute = getCurrentRouteObj();
    if (currentRoute) {
      setSelectedKeys(currentRoute?.functionCode ? [currentRoute.functionCode] : []);
      setOpenKeys(currentRoute?.groupName ? Array.from(new Set([currentRoute.groupName, ...openKeys])) : [...openKeys]);
    }
  }, [location.pathname, menu, menuList])

  /**
   * 点击菜单方法
   * @param obj 菜单对象
   */
  const onMenuClick = (obj: any) => {
    const menuObj: MenuType | undefined = menuList.find((ele: MenuType) => obj.key === ele.functionCode);
    if (menuObj) {
      history.push(`${menuObj.visitURL}`);
    }
  }

  /**icon的type如果为""，会控制台报错，因此用" "代替 */
  const setMenuListDom = (list: MenuType[], showIcon = true) => {
    return list.map((ele: MenuType) =>
      ele.children?.length ? (
        <SubMenu
          key={ele.groupName}
          title={
            <Row type="flex" align="middle">
              <VhIcon type={ele.groupIconName} />
              <div>{ele.groupName}</div>
            </Row>
          }
        >
          {setMenuListDom(ele.children, true)}
        </SubMenu>
      ) : (
        <Item key={ele.functionCode}>
          {showIcon ? <VhIcon type={`${ele.iconName ? ele.iconName : " "}`} /> : null}
          <span>{ele.functionName}</span>
        </Item>
      )
    )
  }

  /**
   * 注：
   * 如果不考虑回退/前进按钮，那么Menu组件只需设置defaultSelectedKeys、defaultOpenKeys，
   * 后续所有操作都由该组件接管，而这两个default只在首次赋值时生效，
   * 所以要用menuList做个判断。
   * 
   * 如果考虑回退/前进按钮，那么只能设置selectdKeys、openKeys
   * 
   * ！！！特别注意！！！，新项目(采用antd4 + react17)，使用defaultSelectedKeys后，在切换显示模式时，头部会早于其他页面进行颜色切换，
   * 老项目(也就是本项目采用antd3x + react16.14)在切换模式时头部和其他组件是同时切换的，
   * 所以新项目暂时改用selectedKeys，并在onMenuClick中增加setSelectedKeys([obj.key]);
   */
  return (
    <Sider className="smarthr-sider" style={{ backgroundColor: "#fff" }} trigger={null} collapsible={true} collapsed={collapse}>
      {menuList?.length
        ? <Menu
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          mode="inline"
          theme="light"
          onClick={onMenuClick}
          onOpenChange={setOpenKeys}
        >
          {setMenuListDom(menu, false)}
        </Menu>
        : null
      }
    </Sider>
  );
}

export default withRouter(VhMenu);
