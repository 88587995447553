import fetchApi from "utils/fetchApi";

/**查询合同公司列表 */
const fetchContractCompanyList = (data: any) => fetchApi("post", "api/contract/company/selectPageList", data)

/** 修改合同公司 */
const fetchContractCompanyUpdate = (data: any) => fetchApi("post", "api/contract/company/update", data)

/** 校验合同公司 */
const fetchContractCompanyValidate = (data: any) => fetchApi("post", "api/contract/company/valid", data)

/**添加合同公司 */
const fetchContractCompanyAdd = (data: any) => fetchApi("post", "api/contract/company/insert", data)

/**删除合同公司 */
const fetchContractCompanyDelete = (id: any) => fetchApi("get", `api/contract/company/delete/${id}`)

/**合同公司 导出 */
const fetchContractCompanyExport = (data: any) => fetchApi("post", "api/contract/company/export", data)

export {
  fetchContractCompanyList,
  fetchContractCompanyUpdate,
  fetchContractCompanyValidate,
  fetchContractCompanyAdd,
  fetchContractCompanyDelete,
  fetchContractCompanyExport
}