import React, { Component } from "react";
import { connect } from "dva";
import { RouteComponentProps, withRouter, Redirect } from "dva/router";
import Tools from "utils/tools";
import { ApplicationState } from "models";

interface PropsFromState extends RouteComponentProps {
  menuList: any[];
}

type AllProps = PropsFromState;

const mapStateToProps = ({ app }: ApplicationState) => ({
  menuList: app.menuList || []
});

const redirectPage = <Redirect to="/no-auth" />;

class MsExternal extends Component<AllProps> {

  public render() {
    const { menuList, location } = this.props;
    const path = location.pathname;
    const id = path.substring(path.lastIndexOf("/") + 1);
    let externalAddr = ""
    if (id) {
      const menuData = menuList.reduce((res: any[], item: any) => {
        return res.concat(item.children || [])
      }, [])
      const url = Tools.mappingFilter(menuData, +id, "id", "externalUrl");
      if (url) {
        externalAddr = `${url}${url.indexOf("?") < 0 ? "?" : "&"}t=${new Date().getTime()}`;
      }
    }
    if (!externalAddr) {
      return redirectPage;
    }
    return (
      <iframe src={externalAddr} title="external" className="external" />
    )
  }
}

export default withRouter(connect(mapStateToProps)(MsExternal));
