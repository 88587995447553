import React, { Component } from "react";
import { Route, RouteComponentProps, Redirect, withRouter } from "dva/router";
import { Menu } from "models/app";
import { APP_CODE } from "common/constants";
import MsLazy from "components/ms-lazy/MsLazy";
import NoAuth from "components/ms-noauth/MsNoAuth";
import MsExternal from "components/ms-external/MsExternal";
import MsHome from "pages/ms-home/MsHome";


/**
 * 匹配的路由页面类型
 * 
 * code:      权限的编码
 * exact:     路由是否完全匹配
 * component: 匹配的组件
 */
export interface AuthRoute {
  code: string;
  exact: boolean;
  component: any;
}

/**
 * 菜单权限拓展
 * 
 * params: 路由后面的参数
 */
export interface AuthMenu extends Menu {
  params?: string;
  children?: any[]
}

/**
 * 父组件传递数据
 */
interface PropsOwn {
  menuList: AuthMenu[];
}

type AllProps = PropsOwn & RouteComponentProps;

/**无权限路由 */
const reRoute = <Redirect to="/shr/no-auth" />

/**人员管理 */
const MsEmployeeRoute = MsLazy(() => import("pages/ms-employee/ms-employee-roster/MsEmployeeRoute"));
const MsEmployeePassRoute = MsLazy(() => import("pages/ms-employee/ms-employee-pass/MsEmployeePassRoute"));
const MsEmployeeTransferRoute = MsLazy(() => import("pages/ms-employee/ms-employee-transfer/MsEmployeeTransferRoute"));
const MsOnBoardRoute = MsLazy(() => import("pages/ms-on-boarding/MsOnBoardRoute"));
const MsLeaveOfficeRoute = MsLazy(() => import("pages/ms-leave-office/MsLeaveOfficeRoute"));

/**组织管理 */
const MsOrgPost = MsLazy(() => import("pages/ms-organization/ms-org-post/MsOrgPost"));
const MsOrgRank = MsLazy(() => import("pages/ms-organization/ms-org-rank/MsOrgRank"));
const MsOrgWorkPlace = MsLazy(() => import("pages/ms-organization/ms-org-workplace/MsOrgWorkPlace"));
const MsOrgStructureRoute = MsLazy(() => import("pages/ms-organization/ms-org-structure/MsOrgStructureRoute"));

/**合同管理 */
const MsContractCompany = MsLazy(() => import("pages/ms-contract-company/MsContractCompany"));

/**社保管理 */
const MsSIEmployeesRoute = MsLazy(() => import("pages/ms-social-insure-management/ms-si-employees/MsSIEmployeesRoute"));
const MsSiPolicy = MsLazy(() => import("pages/ms-social-insure-management/ms-si-policy/MsSIPolicy"));
const MsSIProgressRoute = MsLazy(() => import("pages/ms-social-insure-management/ms-si-progress/MsSIProgressRoute"));

/**设置 */
const MsField = MsLazy(() => import("pages/ms-setting/ms-field/MsField"));
const MsAdminManage = MsLazy(() => import("pages/ms-setting/ms-permission/MsPermission"));
const MsApplicationConfig = MsLazy(() => import("pages/ms-setting/ms-application-config/MsApplicationConfig"));

const routes: AuthRoute[] = [
  /**组织架构 */
  { code: `${APP_CODE}10001`, exact: false, component: MsOrgStructureRoute },
  { code: `${APP_CODE}10002`, exact: false, component: MsOrgPost },
  { code: `${APP_CODE}10003`, exact: false, component: MsOrgRank },
  { code: `${APP_CODE}10004`, exact: false, component: MsOrgWorkPlace },
  /**人员管理 */
  { code: `${APP_CODE}10005`, exact: false, component: MsEmployeeRoute },
  { code: `${APP_CODE}10006`, exact: false, component: MsOnBoardRoute },
  { code: `${APP_CODE}10007`, exact: false, component: MsEmployeePassRoute },
  { code: `${APP_CODE}10008`, exact: false, component: MsLeaveOfficeRoute },
  { code: `${APP_CODE}10009`, exact: false, component: MsEmployeeTransferRoute },
  /**合同公司 */
  { code: `${APP_CODE}10010`, exact: false, component: MsContractCompany },
  /**社保管理 */
  { code: `${APP_CODE}10011`, exact: false, component: MsSIEmployeesRoute },
  { code: `${APP_CODE}10012`, exact: false, component: MsSIProgressRoute },
  { code: `${APP_CODE}10013`, exact: false, component: MsSiPolicy },
  /**设置中心 */
  { code: `${APP_CODE}10014`, exact: false, component: MsField },
  { code: `${APP_CODE}10015`, exact: false, component: MsAdminManage },
  { code: `${APP_CODE}10016`, exact: false, component: MsApplicationConfig }
]


class MsAuthRoute extends Component<AllProps> {

  render() {
    const { location, menuList = [] } = this.props;

    if (!menuList[0]?.children?.length) {
      return null
    }

    switch (location.pathname) {
      case "/shr": {
        return <Route path="/shr" exact={false} component={MsHome} />
      }
      case "/shr/no-auth": {
        return <Route path="/shr/no-auth" exact={true} component={NoAuth} />
      }
    }

    const menuData = menuList.reduce((res: AuthMenu[], item: AuthMenu) => res.concat(item.children || []), [])

    const menu: AuthMenu | undefined = menuData.find((item: AuthMenu) => item.visitURL ? location.pathname.indexOf(item.visitURL) > -1 : false);

    // 当前链接地址有权限的场合
    if (menu) {
      if (menu.externalUrl) {
        return <Route path={menu.visitURL} exact={true} component={MsExternal} />
      }
      const route = menu.functionCode && routes.find((item: AuthRoute) => item.code === menu.functionCode);
      return route ? <Route path={`${menu.visitURL}${menu.params || ""}`} exact={route.exact} component={route.component} /> : reRoute
    } else {
      return reRoute
    }
  }
}

export default withRouter(MsAuthRoute);
