import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { VhTools } from "msd";
import {
  fetchApplicationConfigList,
  fetchApplicationConfigAdd,
  fetchApplicationConfigUpdate
} from "services/applicationConfig";
import { modelExtend } from "./common";


/**应用配置列表类型 */
export interface ApplicationConfigListTypes {
  [x: string]: any;
  /** 涉及平台 */
  platform: string | undefined;
  /**应用类型 */
  applicationType: string | undefined;
  /**应用参数 */
  applicationParams: string;
  /**参数内容 */
  paramsContent: string;
  /**状态 */
  status: string | undefined;
  /**更新时间、更新人 */
  updateContent: string;
  companyId: number | null;
  isDel: number | null;
  appId: number | number;
  cooperativePlatformType: string | null;
  cooperativeAppType: string | null;
  configId: number | null;
  enableFlag: number | null;
}

/**应用参数列表类型 */
export interface ApplicationParamsType {
  /**公司ID */
  companyId: number | null;
  /** */
  isDel: string | null;
  /** */
  creatorId: string | null;
  /** */
  creatorName: string | null;
  /** */
  createTime: string | null;
  /** */
  modifierId: string | null;
  /** */
  modifierName: string | null;
  /** */
  modifyTime: string | null;
  /** */
  appId: string | null;
  /**是否必填:0-否，1-是 */
  necessaryFlag: number | null;
  /**参数名称 */
  itemName: string | null;
  /**参数CODE */
  itemCode: string | null;
  /**参数值 */
  itemValue: string | null;
}

/**应用列表接口 */
export interface AppListType {
  cooperativeAppType: string;
  cooperativeAppTypeName: string;
  appId: number;
  existed: number;
}

/**平台列表接口 */
export interface PlatformListType {
  cooperativePlatformType: string,
  cooperativePlatformTypeName: string,
  types: AppListType[];
}



/**应用配置模块State对象 */
export interface ApplicationConfigState {
  applicationConfigList: ApplicationConfigListTypes[];
  applicationConfigListPagination: IPagination;
}

const initListPagination: IPagination = {
  pageSize: VhTools.getInitPagination().pageSize,
  current: VhTools.getInitPagination().current,
  total: 0
}

/**应用配置模块初始化State */
export const initApplicationConfigState: ApplicationConfigState = {
  applicationConfigList: [],
  applicationConfigListPagination: initListPagination
}

const namespace: string = "applicationConfig";

export default modelExtend<ApplicationConfigState>({
  namespace,
  state: initApplicationConfigState,
  effects: {
    /**查询应用配置列表 */
    *queryApplicationConfigList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const { fetchMaxData } = payload;
      const res: any = yield call(fetchApplicationConfigList, payload);
      if (fetchMaxData) { return res }
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            applicationConfigList: res.data.records || [],
            applicationConfigListPagination: res.data.pageIndex ? {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            } : initListPagination
          }
        })
      }
    },
    /**添加应用配置 */
    *doApplicationConfigAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchApplicationConfigAdd, payload);
      return res
    },
    /**更新应用配置 */
    *doApplicationConfigUpdate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchApplicationConfigUpdate, payload);
      return res
    }
  }
})
