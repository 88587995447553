import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { StatisticsDataType } from "components/ms-statistics/MsStatistics";
import { VhTools } from "msd";
import {
  fetchEmployeeExport,
  fetchEmployeeExportAll,
  fetchEmployeeList,
  fetchEmployeeStatistics,
  fetchEmployeeValidate,
  fetchEmployeeAdd,
  fetchEmployeeDoAdjust,
  fetchGroupEmployee,
  fetchTemplateUrl,
  fetchExcelImport,
  fetchImportHistory,
  fetchEmployeeLeaveOffice,
  fetchEmployeeInfo,
  fetchFieldsConfigGroups,
  fetchEmployeeBankInfo,
  fetchEmployeeJobUpdate,
  fetchEmployeePersonalUpdate,
  fetchEmployeeBankUpdate,
  fetchEducationHistory,
  fetchEmergencyUser,
  fetchEmergencyUserAdd,
  fetchEducationHistoryAdd,
  fetchEducationHistoryUpdate,
  fetchEducationHistoryDelete,
  fetchEmergencyUserUpdate,
  fetchEmergencyUserDelete,
  fetchFamilyMember,
  fetchFamilyMemberAdd,
  fetchFamilyMemberUpdate,
  fetchFamilyMemberDelete,
  fetchWorkHistory,
  fetchWorkHistoryAdd,
  fetchWorkHistoryUpdate,
  fetchWorkHistoryDelete,
  fetchContractRecord,
  fetchContractRecordAdd,
  fetchContractRecordUpdate,
  fetchContractRecordDelete,
  fetchTransferRecord,
  fetchFileAttachment,
  fetchFileAttachmentAdd,
  fetchFileAttachmentUpdate,
  fetchFileAttachmentDelete,
  fetchEmployeeInvite,
  fetchEmployeeJobValidate,
  fetchEmployeePersonalValidate,
  fetchEmployeeBankValidate,
  fetchEmergencyUserValidate,
  fetchEducationHistoryValidate,
  fetchFamilyMemberValidate,
  fetchWorkHistoryValidate,
  fetchContractRecordValidate
} from "services/employee";
import { modelExtend } from "./common";

// 花名册列表类型
export interface EmployeeListType {
  /**公司ID */
  companyId: string;
  /**转正申请状态：0-未申请，5-审批中，10-已通过，15-已驳回 */
  applyRegularStatus: number;
  /**银行卡号 */
  bankNo: string;
  /**出生日期 */
  birthdayName: string;
  /** 证件号 */
  certificateNo: string;
  /**证件类型 */
  certificateTypeName: string;
  /**最新合同公司 */
  contractCompanyName: string;
  /**最新合同到期日期 */
  contractEndDate: string;
  /**最新合同开始日期 */
  contractStartDate: string;
  /**最新合同类型 */
  contractTypeName: string;
  /**合同公司CODE, 对应title */
  contractCompanyCode: string;
  /**创建时间 */
  createTime: string;
  /**创建人ID */
  creatorId: number;
  /** 创建人姓名 */
  creatorName: string;
  /**最高学历学位 */
  degreeName: string;
  /**开户行 */
  depositBank: string;
  /**主要紧急联系人电话 */
  emergencyMobile: string;
  /**主要紧急联系人姓名 */
  emergencyName: string;
  /**主要紧急联系人关系 */
  emergencyRelationName: string;
  /**在职信息自定义字段json */
  employExtJson: string;
  /**员工信息code */
  employeeCode: string;
  /**工作邮箱 */
  employeeEmail: string;
  /**工号 */
  employeeNo: string;
  /**	员工来源 1.手工添加 2.扫码入职 */
  employeeSource: number;
  /**员工状态 0.待入职 1.在职 2.已取消入职 3.待离职 4.已离职 */
  employeeStatus: number;
  /**员工状态名称 */
  employeeStatusName: string;
  /**雇员类型名称 */
  employeeTypeName: string;
  /** 入职日期 */
  enrollmentDateName: string;
  /**首次工作日期 */
  firstWorkDateName: string;
  /**部门(全路径) */
  fullGroupNames: string;
  /**	性别，系统字典 */
  gender: number;
  /**性别（字符串） */
  genderName: string;
  /**部门编码 */
  groupCodes: string;
  /**一级部门 */
  groupName1: string;
  /**二级部门 */
  groupName2: string;
  /**三级部门 */
  groupName3: string;
  /**四级部门 */
  groupName4: string;
  /**五级部门 */
  groupName5: string;
  /**部门名称, 直属部门 */
  groupNames: string;
  /**最高学历 */
  highestEducationBackgroupName: string;
  /**最高学历结束日期 */
  highestEndDate: string;
  /**最高学历开始日期 */
  highestStartDate: string;
  /**主键 */
  id: number;
  /**会员域ID */
  ipRoleId: number;
  /**入职登记表是否提交 0未提交 1已提交 */
  isRegister: number;
  /**上级CODE */
  leaderCode: string;
  /**上级名称 */
  leaderName: string;
  /**最高学历专业 */
  major: string;
  /**婚姻状况 */
  maritalStatusName: string;
  /**手机号码 */
  mobile: string;
  /**修改人ID */
  modifierId: number;
  /**修改人姓名 */
  modifierName: string;
  /**修改时间 */
  modifyTime: string;
  /**民族 */
  nationName: string;
  /**籍贯 */
  nativePlaceName: string;
  /**政治面貌 */
  politicsStatusName: string;
  /**职位CODE */
  postCode: string;
  /**岗位名称 */
  postName: string;
  /** 前工作单位 */
  preCompanyName: string;
  /**前工作开始日期 */
  preEndTime: string;
  /**前工作部门 */
  preGroupName: string;
  /**前工作职位 */
  prePostName: string;
  /**前工作开始日期 */
  preStartTime: string;
  /**前工作内容描述 */
  preWorkDes: string;
  /**试用期 */
  probationPeriod: string;
  probationPeriodName: string;
  /**公积金账号 */
  providentAccount: string;
  /**职级CODE */
  rankCode: string;
  /**职级 */
  rankName: string;
  /**户口类型 */
  registerResidenceName: string;
  /**户口所在地 */
  registerResidencePlace: string;
  /**户口类型 */
  registerResidenceType: number;
  /**转正日期 */
  regularDate: string;
  /**转正日期 */
  regularDateName: string;
  /** 转正状态：21-待转正，22-已转正，23-取消转正 */
  regularStatus: number;
  /**转正状态名称 */
  regularStatusName: string;
  /**现居住地 */
  residencePlace: string;
  /**最高学历学校 */
  school: string;
  /**离职日期 */
  separationDate: string;
  /**最新合同签订次数 */
  signNum: string;
  /**社保账号 */
  socialSecurityAccount: string;
  /**合同公司名称 */
  title: string;
  /**离职原因 */
  transactionReason: string;
  /**离职类型 */
  transactionTypeName: string;
  /**个人邮箱 */
  userEmail: string;
  /**个人信息自定义字段json */
  userExtJson: string;
  /**姓名 */
  userName: string;
  /**工作地点CODE */
  workPlaceCode: string;
  /**工作地点名称 */
  workPlaceName: string;
}

/**部门员工列表类型 */
export interface GroupEmployeeListType {
  employeeCode: string;
  employeeName: string;
}

/**
 * 日志列表类型
 */
export interface LogListType {
  batchNo: string;
  companyId: number;
  createTime: number;
  creatorId: number;
  creatorName: string;
  employeeCode: string;
  errorFileName: string;
  errorFilePath: string;
  failedNum: number;
  id: number;
  modifierId: number;
  modifierName: string;
  modifyTime: number;
  oriFileName: string;
  oriFilePath: string;
  processType: number;
  submitTime: number;
  totalNum: number;
}


/**自定义字段类型 */
export interface EmployeeFieldConfigType {
  companyId: number;
  createTime: number;
  creatorId: number;
  creatorName: string;
  editFlag: number;
  employeeDisplayFlag: number;
  employeeNecessaryFlag: number;
  enableFlag: number;
  enumCode: string;
  fieldCode: string;
  fieldFillPrompt: string;
  fieldName: string;
  fieldType: number;
  fieldLength: number;
  groupAndFieldCode: string;
  groupCode: string;
  id: number;
  isDel: number;
  isSys: number;
  modifierId: number;
  modifierName: string;
  modifyTime: number;
  registerFormDisplayFlag: number;
  registerFormNecessaryFlag: number;
  selectboxes: any;
  sort: number;
}

/**自定义分组类型 */
export interface EmployeeGroupConfigType {
  companyId: number;
  createTime: number;
  creatorId: number;
  creatorName: string;
  editFlag: number;
  employeeDisplayFlag: number;
  enableFlag: number;
  groupCode: string;
  groupName: string;
  id: number;
  isDel: number;
  isSys: number;
  modifierId: number;
  modifierName: string;
  modifyTime: number;
  registerFormDisplayFlag: number;
  sort: number;
  fields: EmployeeFieldConfigType[];
}

/**异动记录接口 */
export interface TransferListType {
  /**业务类型：10-入职， 20-转正， 30-调动，40-离职 */
  bizType: string;
  /**	公司ID */
  companyId: string;
  /**	合同公司 */
  contractCompanyName: string;
  /**	创建时间 */
  createTime: string;
  /**创建人ID */
  creatorId: string;
  /**创建人姓名 */
  creatorName: string;
  /**门名称列表 */
  groupNames: string;
  /**主键 */
  id: string;
  /**异动状态：0-未生效，1-已生效 */
  isAssert: string;
  /**上级 */
  leaderName: string;
  /**修改人ID */
  modifierId: string;
  /**修改人姓名 */
  modifierName: string;
  /**	修改时间 */
  modifyTime: string;
  /**操作类型：31-调动-晋升，32-调动-降级，33-调动-调岗，41-离职-主动，42-离职-被动 */
  operateType: string;
  /**岗位 */
  postName: string;
  /**职级 */
  rankName: string;
  /**对应bizType,, 入职日期，转正日期，生效日期，离职期 */
  validDate: string;
  /**	工作地点 */
  workPlaceName: string;
}

export interface EmployeeSearchParamsType {
  [x: string]: any;
  /**模糊查询姓名或手机号 */
  searchKey: string;
}

export interface ExtraPageConfigType {
  /**搜索栏是否展开 */
  isExpanded: boolean;
  /**头部卡片选中项 */
  cardIndex: number;
}

export interface EmployeeState {
  statisticsData: StatisticsDataType[][];
  groupEmployeeList: GroupEmployeeListType[];
  list: EmployeeListType[];
  pagination: IPagination;
  employeeInfo: EmployeeListType | null;
  employeeGroupConfig: EmployeeGroupConfigType[];
  searchParams: EmployeeSearchParamsType;
  extraPageConfig: ExtraPageConfigType;
  transferList: TransferListType[];
  transferListPagination: IPagination;
}

export const initEmployeeState: EmployeeState = {
  statisticsData: [
    [{ label: "在职", value: 0, searchType: "1" }],
    [
      { label: "全职", value: 0, searchType: "2" },
      { label: "实习", value: 0, searchType: "3" },
      { label: "劳务/外包/其它", value: 0, searchType: "4" },
    ],
    [
      { label: "试用中", value: 0, searchType: "5" },
      { label: "已转正", value: 0, searchType: "6" }
    ],
    [{ label: "待离职", value: 0, searchType: "7" }],
  ],
  groupEmployeeList: [],
  list: [],
  pagination: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  employeeInfo: null,
  employeeGroupConfig: [],
  transferList: [],
  transferListPagination: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  searchParams: {
    searchKey: "",
    employeeTypeT: [],
    leaderCodeT: [],
    groupCodeT: [],
    rankCodeT: [],
    postCodeT: [],
    workPlaceCodeT: [],
    genderT: [],
    contractCompanyCodeT: [],
    highestEducationBackgroupT: [],
    enrollmentDateStart: "",
    enrollmentDateEnd: "",
    pageIndex: VhTools.getInitPagination().current,
    pageSize: VhTools.getInitPagination().pageSize,
  },
  extraPageConfig: {
    isExpanded: false,
    cardIndex: 0
  }
}

const namespace: string = "employee";

const delay = (time: number) => new Promise((resolve: any) => setTimeout(resolve, time))

export default modelExtend<EmployeeState>({
  namespace,
  state: initEmployeeState,
  effects: {
    /**查询花名册列表 */
    *queryEmployeeList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            searchParams: { ...payload },
            list: res.data.records || [],
            pagination: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
      return res;
    },
    /**查询花名册表头统计 */
    *queryEmployeeStatistics({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeStatistics, payload);
      if (res?.result === 0 && res.data?.records?.length > 0) {
        const statisticsData = [
          [
            { label: "在职", value: res.data.records.find(({ st }: any) => st === "totalNum")?.num || 0, searchType: "totalNum" }],
          [
            { label: "全职", value: res.data.records.find(({ st }: any) => st === "fullTimeNum")?.num || 0, searchType: "fullTimeNum" },
            { label: "实习", value: res.data.records.find(({ st }: any) => st === "intershipNum")?.num || 0, searchType: "intershipNum" },
            { label: "劳务/外包/其它", value: res.data.records.find(({ st }: any) => st === "othersNum")?.num || 0, searchType: "othersNum" },
          ],
          [
            { label: "试用中", value: res.data.records.find(({ st }: any) => st === "probationNum")?.num || 0, searchType: "probationNum" },
            { label: "已转正", value: res.data.records.find(({ st }: any) => st === "regularNum")?.num || 0, searchType: "regularNum" }
          ],
          [
            { label: "待离职", value: res.data.records.find(({ st }: any) => st === "forSeperationNum")?.num || 0, searchType: "forSeperationNum" }
          ],
        ]
        yield put({
          type: "updateState",
          payload: { statisticsData }
        })
      }
    },
    /**提交校验唯一 */
    *doEmployeeValidate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeValidate, payload);
      return res
    },
    /**花名册添加员工 */
    *doEmployeeAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeAdd, payload);
      return res
    },
    /**花名册发起调动 */
    *doEmployeeAdjust({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeDoAdjust, payload);
      return res
    },
    /**查询部门员工信息-负责人 */
    queryGroupEmployeeList: [
      function* ({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
        yield call(delay, 500);
        const res: any = yield call(fetchGroupEmployee, payload);
        if (res?.result === 0) {
          yield put({
            type: "updateState",
            payload: {
              groupEmployeeList: res.data || []
            }
          })
        }
      },
      { type: "takeLatest" }
    ],
    /**花名册列表导出 */
    *doEmployeeExport({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeExport, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
    /**花名册列表全量导出 */
    *doEmployeeExportAll({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeExportAll, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
    /**查询批量导入模板地址 */
    *queryImportTemplateUrl(_action: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchTemplateUrl);
      return res
    },
    /**花名册批量导入 */
    *doExcelImport({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchExcelImport, payload);
      return res
    },
    /**查询导入记录 */
    *queryImportList({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchImportHistory, payload);
      return res
    },
    /**花名册-办理离职 */
    *doEmployeeLeaveOffice({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeLeaveOffice, payload);
      return res
    },
    /**花名册-邀请完善信息 */
    *queryEmployeeInvite({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeInvite, payload);
      return res
    },
    /**获取员工详情自定义字段 */
    *queryFieldsConfigGroups({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFieldsConfigGroups, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            employeeGroupConfig: res.data || []
          }
        })
      }
    },
    /**花名册详情-查询个人信息、在职信息 */
    *queryEmployeeInfo({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeInfo, payload);
      return res
    },
    /**花名册详情-校验在职信息 */
    *doValidateEmployeeOnTheJob({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeJobValidate, payload);
      return res
    },
    /**花名册详情-更新在职信息 */
    *doUpdateEmployeeOnTheJob({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeJobUpdate, payload);
      return res
    },
    /**花名册详情-校验个人信息 */
    *doValidateEmployeePersonal({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeePersonalValidate, payload);
      return res
    },
    /**花名册详情-更新个人信息 */
    *doUpdateEmployeePersonal({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeePersonalUpdate, payload);
      return res
    },
    /**花名册详情-查询银行卡信息 */
    *queryEmployeeBankInfo({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeBankInfo, payload);
      return res
    },
    /**花名册详情-校验银行卡信息 */
    *doValidateEmployeeBank({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeBankValidate, payload);
      return res
    },
    /**花名册详情-更新银行卡信息 */
    *doUpdateEmployeeBank({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeBankUpdate, payload);
      return res
    },
    /**花名册详情-查询紧急联系人信息 */
    *queryEmergencyUserInfo({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmergencyUser, payload);
      return res
    },
    /**花名册详情-添加紧急联系人信息 */
    *doAddEmergencyUserInfo({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmergencyUserAdd, payload);
      return res
    },
    /**花名册详情-校验紧急联系人信息 */
    *doValidateEmergencyUserInfo({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmergencyUserValidate, payload);
      return res
    },
    /**花名册详情-更新紧急联系人信息 */
    *doUpdateEmergencyUserInfo({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmergencyUserUpdate, payload);
      return res
    },
    /**花名册详情-删除紧急联系人信息 */
    *doDeleteEmergencyUserInfo({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmergencyUserDelete, payload);
      return res
    },
    /**花名册详情-查询教育经历信息 */
    *queryEducationHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEducationHistory, payload);
      return res
    },
    /**花名册详情-添加教育经历信息 */
    *doAddEducationHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEducationHistoryAdd, payload);
      return res
    },
    /**花名册详情-校验教育经历信息 */
    *doValidateEducationHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEducationHistoryValidate, payload);
      return res
    },
    /**花名册详情-更新教育经历信息 */
    *doUpdateEducationHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEducationHistoryUpdate, payload);
      return res
    },
    /**花名册详情-删除教育经历信息 */
    *doDeleteEducationHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEducationHistoryDelete, payload);
      return res
    },
    /**花名册详情-查询家庭成员信息 */
    *queryFamilyMember({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFamilyMember, payload);
      return res
    },
    /**花名册详情-添加家庭成员信息 */
    *doAddFamilyMember({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFamilyMemberAdd, payload);
      return res
    },
    /**花名册详情-校验家庭成员信息 */
    *doValidateFamilyMember({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFamilyMemberValidate, payload);
      return res
    },
    /**花名册详情-更新家庭成员信息 */
    *doUpdateFamilyMember({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFamilyMemberUpdate, payload);
      return res
    },
    /**花名册详情-删除家庭成员信息 */
    *doDeleteFamilyMember({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFamilyMemberDelete, payload);
      return res
    },
    /**花名册详情-查询工作经历信息 */
    *queryWorkHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkHistory, payload);
      return res
    },
    /**花名册详情-添加工作经历信息 */
    *doAddWorkHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkHistoryAdd, payload);
      return res
    },
    /**花名册详情-校验工作经历信息 */
    *doValidateWorkHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkHistoryValidate, payload);
      return res
    },
    /**花名册详情-更新工作经历信息 */
    *doUpdateWorkHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkHistoryUpdate, payload);
      return res
    },
    /**花名册详情-删除工作经历信息 */
    *doDeleteWorkHistory({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkHistoryDelete, payload);
      return res
    },
    /**花名册详情-查询合同记录信息 */
    *queryContractRecord({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractRecord, payload);
      return res
    },
    /**花名册详情-添加合同记录信息 */
    *doAddContractRecord({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractRecordAdd, payload);
      return res
    },
    /**花名册详情-校验合同记录信息 */
    *doValidateContractRecord({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractRecordValidate, payload);
      return res
    },
    /**花名册详情-更新合同记录信息 */
    *doUpdateContractRecord({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractRecordUpdate, payload);
      return res
    },
    /**花名册详情-删除合同记录信息 */
    *doDeleteContractRecord({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractRecordDelete, payload);
      return res
    },
    /**花名册详情-获取材料附件信息 */
    *queryFileAttachment({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFileAttachment, payload);
      return res
    },
    /**花名册详情-上传材料附件信息 */
    *doAddFileAttachment({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFileAttachmentAdd, payload);
      return res
    },
    /**花名册详情-重新上传材料附件信息 */
    *doUpdateFileAttachment({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFileAttachmentUpdate, payload);
      return res
    },
    /**花名册详情-上传材料附件信息 */
    *doDeleteFileAttachment({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchFileAttachmentDelete, payload);
      return res
    },
    /**花名册详情-查询异动记录信息 */
    *queryTransferRecord({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchTransferRecord, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            transferList: res.data.records || [],
            transferListPagination: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
      return res
    },
  }
})
