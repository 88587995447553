import fetchApi from "utils/fetchApi";
import Tools from "utils/tools";

/**查询参保账户（查询条件数据源） */
const fetchSIEmployeesAccounts = (data: any) => fetchApi("post", "api/insurance/personal/selectInsuranceAccounts", data)

/**查询参保人员统计 */
const fetchSIEmployeesStatistics = () => fetchApi("get", "api/insurance/personal/statistics")

/**查询参保人员列表 */
const fetchSIEmployeesList = (data: any) => fetchApi("get", Tools.appendParams("api/insurance/personal/queryList", data))

/**单人参保 */
const fetchSIEmployeesInsurance = (data: any) => fetchApi("post", "api/insurance/personal/participateInsurance", data)

/**修改参保信息 */
const fetchSIEmployeesInsuranceUpdate = (data: any) => fetchApi("post", "api/insurance/personal/updateInsurance", data)

/**暂不缴纳 */
const fetchNoInsurance = (employeeCode: any) => fetchApi("get", `api/insurance/personal/notPaidInsurance/${employeeCode}`)

/**导出 */
const fetchExportList = (data: any) => fetchApi("post", Tools.appendParams("api/insurance/personal/export", data))

/**可参保人员列表 */
const fetchInsuredPersonList = (data: any) => fetchApi("get", Tools.appendParams("api/insurance/personal/choose/insured/person", data))

/**查询人员参保详情 */
const fetchInsuredPersonDetail = (employeeCode: any) => fetchApi("get", `api/insurance/personal/detail/${employeeCode}`)

export {
  fetchSIEmployeesAccounts,
  fetchSIEmployeesStatistics,
  fetchSIEmployeesList,
  fetchSIEmployeesInsurance,
  fetchNoInsurance,
  fetchExportList,
  fetchInsuredPersonList,
  fetchInsuredPersonDetail,
  fetchSIEmployeesInsuranceUpdate
}