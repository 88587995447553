import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { StatisticsDataType } from "components/ms-statistics/MsStatistics";
import { VhTools } from "msd";
import { fetchEmployeeList, fetchEmployeeStatistics } from "services/employee";
import {
  fetchGroupTree,
  fetchGroupAdd,
  fetchGroupUpdate,
  fetchGroupDelete,
  fetchGroupQuery,
  fetchGroupSort,
  fetchGroupValidate,
} from "services/group";
import { modelExtend } from "./common";
import { EmployeeListType } from "./employee";

// 部门树形类型
export interface GroupTreeType {
  /**部门编码 */
  groupCode: string;
  /**部门名称 */
  groupName: string;
  /**上级编码 */
  leaderCode: string;
  /**上级名称 */
  leaderName: string;
  /**上级部门编码 */
  parentGroupCode: string;
  /**排序 */
  sort: number;
  /**子节点 */
  children: any[];
}

export interface GroupState {
  /**统计数据 */
  statisticsData: StatisticsDataType[][];
  /**部门数据 */
  allGroupTreeList: GroupTreeType[];
  /**展示数据 */
  groupTreeList: GroupTreeType[];
  /**部门人员列表 */
  groupEmployeeList: EmployeeListType[];
  /**列表分页 */
  groupEmployeeListPagination: IPagination;
  /**查询参数 */
  searchParams: any;
  /**查询关键字 */
  searchKey: string;
  /**卡片 */
  cardIndex: number;
}

export const initGroupState: GroupState = {
  statisticsData: [
    [{ label: "在职", value: 0, searchType: "1" }],
    [
      { label: "全职", value: 0, searchType: "2" },
      { label: "实习", value: 0, searchType: "3" },
      { label: "劳务/外包/其它", value: 0, searchType: "4" },
    ],
    [
      { label: "试用中", value: 0, searchType: "5" },
      { label: "已转正", value: 0, searchType: "6" }
    ],
    [{ label: "待离职", value: 0, searchType: "7" }],
  ],
  allGroupTreeList: [],
  groupTreeList: [],
  groupEmployeeList: [],
  groupEmployeeListPagination: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  searchParams: {
    groupCode: "",
    pageIndex: VhTools.getInitPagination().current,
    pageSize: VhTools.getInitPagination().pageSize,
  },
  searchKey: "",
  cardIndex: 0
}

const namespace: string = "group";

export default modelExtend<GroupState>({
  namespace,
  state: initGroupState,
  effects: {
    /**查询部门树形 */
    *queryGroupTree(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupTree);
      if (res?.result === 0) {
        const treeList = res.data
          ? (Array.isArray(res.data) ? res.data : [res.data])
          : []
        yield put({
          type: "updateState",
          payload: {
            searchKey: "",
            allGroupTreeList: treeList,
            groupTreeList: treeList
          }
        })
      }
      return res
    },
    /**刷新部门数据 */
    *refreshGroupTree(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupTree);
      if (res?.result === 0) {
        const treeList = res.data
          ? (Array.isArray(res.data) ? res.data : [res.data])
          : []
        yield put({
          type: "updateState",
          payload: {
            allGroupTreeList: treeList
          }
        })
      }
    },
    /**查询部门人员列表 */
    *queryEmployeeList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            searchParams: { ...payload },
            groupEmployeeList: res.data.records || [],
            groupEmployeeListPagination: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },
    /**查询花名册表头统计 */
    *queryEmployeeStatistics({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeeStatistics, payload);
      if (res?.result === 0 && res.data?.records?.length > 0) {
        const statisticsData = [
          [
            { label: "在职", value: res.data.records.find(({ st }: any) => st === "totalNum")?.num || 0, searchType: "totalNum" }],
          [
            { label: "全职", value: res.data.records.find(({ st }: any) => st === "fullTimeNum")?.num || 0, searchType: "fullTimeNum" },
            { label: "实习", value: res.data.records.find(({ st }: any) => st === "intershipNum")?.num || 0, searchType: "intershipNum" },
            { label: "劳务/外包/其它", value: res.data.records.find(({ st }: any) => st === "othersNum")?.num || 0, searchType: "othersNum" },
          ],
          [
            { label: "试用中", value: res.data.records.find(({ st }: any) => st === "probationNum")?.num || 0, searchType: "probationNum" },
            { label: "已转正", value: res.data.records.find(({ st }: any) => st === "regularNum")?.num || 0, searchType: "regularNum" }
          ],
          [
            { label: "待离职", value: res.data.records.find(({ st }: any) => st === "forSeperationNum")?.num || 0, searchType: "forSeperationNum" }
          ],
        ]
        yield put({
          type: "updateState",
          payload: { statisticsData }
        })
      }
    },
    /**部门搜索 */
    *doGroupQuery({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupQuery, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            searchKey: payload.groupName.trim(),
            groupTreeList: res.data.records || []
          }
        })
      }
    },
    /**校验名称重复 */
    *doGroupValidate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupValidate, payload);
      return res
    },
    /**新增部门 */
    *doGroupAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupAdd, payload);
      return res
    },
    /**更新部门 */
    *doGroupUpdate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupUpdate, payload);
      return res
    },
    /**删除部门 */
    *doGroupDelete({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupDelete, payload);
      return res
    },
    /**部门排序 */
    *doGroupSort({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupSort, payload);
      return res
    }
  }
})
