import fetchApi from "utils/fetchApi";


/** 查询超级管理员列表数据 */
const fetchSuperAdminList = (data: any) => fetchApi("post", "api/superAdmin/querySuperAdminList", data)

/** 添加超级管理员 */
const fetchAddSuperAdmin = (data: any) => fetchApi("post", "api/superAdmin/addSuperAdmin", data)

/** 移除超级管理员 */
const fetchDeleteSuperAdmin = (data: any) => fetchApi("post", "api/superAdmin/deleteSuperAdmin", data)

/** 根据输入名称搜索员工 */
const fetchQueryUser = (data: any) => fetchApi("post", "api/employee/queryEmployeeSimpleInfoForSuperAdmin", data)

export {
  fetchSuperAdminList,
  fetchAddSuperAdmin,
  fetchDeleteSuperAdmin,
  fetchQueryUser
}