import fetchApi from "utils/fetchApi";


/** 查询异动记录列表数据 */
const fetchTransferList = (data: any) => fetchApi("post", "api/employeeOperate/selectList", data)

/** 调整生效日期 */
const fetchUpdateTransferDate = (data: any) => fetchApi("post", "api/employeeOperate/adjustModifyDate", data)

/** 撤销调动 */
const fetchCancelTransfer = (data: any) => fetchApi("post", "api/employeeOperate/adjustCancel", data)

/** 导出 */
const fetchExportList = (data: any) => fetchApi("post", "api/common/exportEmployeeOperate", data)

export {
  fetchTransferList,
  fetchUpdateTransferDate,
  fetchCancelTransfer,
  fetchExportList
}