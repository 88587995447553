import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination, MapType } from "common/types";
import { VhTools } from "msd";
import { fetchTransferList, fetchCancelTransfer, fetchUpdateTransferDate, fetchExportList } from "services/employeeTransfer";
import { modelExtend } from "./common";


/** 异动记录列表类型 */
export interface EmployeeTransferTypes {
  /** 异动后合同公司 */
  afterContractCompanyName: string
  /** 异动后部门名称列表 */
  afterGroupNames: string
  /** 异动后上级 */
  afterLeaderName: string
  /** 异动后岗位 */
  afterPostName: string
  /** 异动后职级 */
  afterRankName: string
  /** 异动后工作地点 */
  afterWorkPlaceName: string
  /** 业务类型：10-入职， 20-调动， 30-调动，40-离职 */
  bizType: number
  /** 公司ID */
  companyId: number
  /** 异动时合同公司 */
  contractCompanyName: string
  /** 创建时间 */
  createTime: string
  /** 创建人ID */
  creatorId: number
  /** 创建人姓名 */
  creatorName: string
  /** 员工信息code */
  employeeCode: string
  /** 异动时部门名称列表 */
  groupNames: string
  /** 主键id */
  id: number
  /** 异动状态：0-未生效，1-已生效 */
  isAssert: number
  /** 异动时上级 */
  leaderName: string
  /** 修改人ID */
  modifierId: number
  /** 修改人姓名 */
  modifierName: string
  /** 修改时间 */
  modifyTime: string
  /** 操作类型：31-调动-晋升，32-调动-降级，33-调动-调岗，41-离职-主动，42-离职-被动 */
  operateType: number
  /** 异动时岗位 */
  postName: string
  /** 异动时职级 */
  rankName: string
  /** 关联编码 */
  relateCode: string
  /** 状态：11-待入职，12-已入职，13-取消入职，21-待调动，22-已调动，23-取消调动，31-待调动，32-已调动，33-取消调动，41-待离职，42-已离职，43-取消离职 */
  status: number
  /** 姓名 */
  userName: string
  /** 生效日期，对应bizType, 入职日期，调动日期，生效日期，离职日期 */
  validDate: string
  /** 异动时工作地点 */
  workPlaceName: string
  /**入职日期 */
  enrollmentDate: string
}

/** 异动记录模块State对象 */
export interface EmployeeTransferState {
  /** 入职记录列表数据 */
  onboardList: EmployeeTransferTypes[]
  /** 调动记录列表数据 */
  passList: EmployeeTransferTypes[]
  /** 调动记录列表数据 */
  transferList: EmployeeTransferTypes[]
  /** 离职记录列表数据 */
  leaveList: EmployeeTransferTypes[];
  /** 列表分页数据 */
  pageData: IPagination;
  /** 搜索条件 */
  searchParams: MapType
}

export const initSearchParams = {
  bizType: 10,
  query: ""
}

/** 异动记录模块初始化State */
export const initEmployeeTransferState: EmployeeTransferState = {
  onboardList: [],
  passList: [],
  transferList: [],
  leaveList: [],
  pageData: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  searchParams: initSearchParams
}

const namespace: string = "employeeTransfer";

export default modelExtend<EmployeeTransferState>({
  namespace,
  state: initEmployeeTransferState,
  effects: {
    /** 查询入职记录列表数据 */
    *queryOnboardList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchTransferList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            onboardList: res.data.records || [],
            pageData: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            },
            searchParams: {
              bizType: payload.bizType,
              query: payload.query
            }
          }
        })
      }
    },
    /** 查询转正记录列表数据 */
    *queryPassList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchTransferList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            passList: res.data.records || [],
            pageData: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            },
            searchParams: {
              bizType: payload.bizType,
              query: payload.query
            }
          }
        })
      }
    },
    /** 查询调动记录列表数据 */
    *queryTransferList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchTransferList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            transferList: res.data.records || [],
            pageData: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            },
            searchParams: {
              bizType: payload.bizType,
              query: payload.query
            }
          }
        })
      }
    },
    /** 查询离职记录列表数据 */
    *queryleaveList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchTransferList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            leaveList: res.data.records || [],
            pageData: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            },
            searchParams: {
              bizType: payload.bizType,
              query: payload.query
            }
          }
        })
      }
    },

    /** 更新生效日期 */
    *updateTransferDate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchUpdateTransferDate, payload)
      return res
    },

    /** 撤销调动 */
    *cancelTransfer({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchCancelTransfer, payload)
      return res
    },

    /** 列表数据导出 */
    *exportList({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchExportList, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
  }
})
