import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { VhTools } from "msd";
import { fetchSIEmployeesAccounts, fetchSIEmployeesStatistics, fetchSIEmployeesList, fetchSIEmployeesInsurance, fetchNoInsurance, fetchInsuredPersonList, fetchExportList, fetchSIEmployeesInsuranceUpdate } from "services/siEmployees";
import { modelExtend } from "./common";
import { StatisticsDataType } from "components/ms-statistics/MsStatistics";

// 参保员工列表类型
export interface SIEmployeesListTypes {
  [x: string]: any;
  accountNumName: string;
  accountOwnerTypeName: string;
  cardCode: string;
  cardType: number;
  cityId: number;
  cityText: string;
  companyId: number;
  costCenterId: number;
  createTime: number;
  creatorId: number
  creatorName: string;
  employeeCode: string;
  employeeId: number;
  employeeType: number;
  employeeTypeName: string;
  groupCodes: string;
  groupNames: string;
  id: number;
  isDel: number;
  mobile: string;
  modifierId: number;
  modifierName: string;
  modifyTime: number;
  name: string;
  personalCode: string;
  pfAccountNum: string;
  pfAccountOwnerType: number;
  pfBase: number;
  pfGroupId: number;
  pfGroupName: string;
  pfStatus: number;
  pfStatusName: string;
  provinceId: number;
  provinceText: string;
  ssAccountNum: string;
  ssAccountOwnerType: number;
  ssBase: number;
  ssGroupId: number;
  ssGroupName: string;
  ssStatus: number;
  ssStatusName: string;
}

// 参保员工列表参数类型
export interface SIEmployeesSearchParamsType {
  [x: string]: any;
  /**模糊查询姓名或手机号 */
  mixParam: string;
}

// 参保员工-账户列表类型
export interface SIEmployeesAccountListType {
  /**账户Id */
  accountId: number;
  /**账户编码 */
  accountNum: string;
}

// 参保员工-可参保人员列表类型
export interface InsuredPersonListType {
  [x: string]: any;
}

/**参保员工State对象 */
export interface SIEmployeesState {
  /** 统计数据 */
  statisticsData: StatisticsDataType[],
  /** 列表数据 */
  siEmployeesList: SIEmployeesListTypes[];
  /** 列表分页数据 */
  pageData: IPagination;
  /**选中卡片 */
  cardIndex: number;
  /**展开、收起 */
  isExpanded: boolean;
  /**查询参数 */
  siEmployeesSearchParams: SIEmployeesSearchParamsType;
  /**查询参数-参保账户列表 */
  siEmployeeAccountList: SIEmployeesAccountListType[];
  /**可参保人员列表 */
  insuredPersonList: InsuredPersonListType[];
  /**可参保人员列表分页数据 */
  insuredPersonListPagination: IPagination;
}

/**参保员工初始化State */
export const initSIEmployeesState: SIEmployeesState = {
  statisticsData: [
    { label: "全部", value: 0, searchType: "" },
    { label: "待参保", value: 0, searchType: "1" },
    { label: "参保中", value: 0, searchType: "2" },
    { label: "停保中", value: 0, searchType: "3" },
    { label: "在保中", value: 0, searchType: "4" },
    { label: "暂不缴纳", value: 0, searchType: "0" },
  ],
  siEmployeesList: [],
  pageData: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  cardIndex: 0,
  isExpanded: false,
  siEmployeesSearchParams: {
    mixParam: "",
    groupCodeT: [],
    employeeTypeT: [],
    cityIdT: [],
    ssStatusT: [],
    pfStatusT: [],
    accountOwnerTypeT: [],
    accountIdT: [],
    globalStatus: "",
    pageIndex: VhTools.getInitPagination().current,
    pageSize: VhTools.getInitPagination().pageSize,
  },
  siEmployeeAccountList: [],
  insuredPersonList: [],
  insuredPersonListPagination: {
    pageSize: 10,
    current: VhTools.getInitPagination().current,
    total: 0
  }
}

const namespace: string = "siEmployees";

export default modelExtend<SIEmployeesState>({
  namespace,
  state: initSIEmployeesState,
  effects: {
    /** 查询筛选框-账户列表 */
    *querySearchAccountList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchSIEmployeesAccounts, payload)
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            siEmployeeAccountList: res.data.records || []
          }
        })
      }
    },
    /** 查询统计数据 */
    *queryStatisticsData(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchSIEmployeesStatistics)
      if (res?.result === 0) {
        const data = [
          { label: "全部", value: res.data?.total || 0, searchType: "" },
          { label: "待参保", value: res.data?.insuranceWait || 0, searchType: "1" },
          { label: "参保中", value: res.data?.insuranceJoining || 0, searchType: "2" },
          { label: "停保中", value: res.data?.insurancStop || 0, searchType: "3" },
          { label: "在保中", value: res.data?.insuranceIng || 0, searchType: "4" },
          { label: "暂不缴纳", value: res.data?.insuranceNone || 0, searchType: "0" },
        ]
        yield put({
          type: "updateState",
          payload: {
            statisticsData: data
          }
        })
      }
    },
    /**查询列表数据 */
    *querySIEmployeesList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchSIEmployeesList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            siEmployeesSearchParams: { ...payload },
            siEmployeesList: res.data.records || [],
            pageData: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },
    /**单人参保 */
    *doEmployeesInsurance({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchSIEmployeesInsurance, payload);
      return res
    },
    /**修改参保信息 */
    *doEmployeesInsuranceUpdate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchSIEmployeesInsuranceUpdate, payload);
      return res
    },
    /** 暂不缴纳 */
    *doNoInsurance({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchNoInsurance, payload)
      return res
    },
    /**可参保人员列表 */
    *queryInsuredPersonList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchInsuredPersonList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            insuredPersonList: res.data.records || [],
            insuredPersonListPagination: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },
    /** 列表数据导出 */
    *exportList({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchExportList, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
  }
})
