import fetchApi from "utils/fetchApi";

/**查询部门树 */
const fetchGroupTree = () => fetchApi("post", "api/organizationalGroup/queryGroupTree")

/**校验重复 */
const fetchGroupValidate = (data: any) => fetchApi("post", "api/organizationalGroup/checkExists", data)

/**新增部门 */
const fetchGroupAdd = (data: any) => fetchApi("post", "api/organizationalGroup/save", data)

/**修改部门 */
const fetchGroupUpdate = (data: any) => fetchApi("post", "api/organizationalGroup/update", data)

/**删除部门 */
const fetchGroupDelete = (data: any) => fetchApi("post", "api/organizationalGroup/delete", data)

/**部门排序 */
const fetchGroupSort = (data: any) => fetchApi("post", "api/organizationalGroup/sort", data)

/**部门搜索 */
const fetchGroupQuery = (data: any) => fetchApi("post", "api/organizationalGroup/queryList", data)

export {
  fetchGroupTree,
  fetchGroupValidate,
  fetchGroupAdd,
  fetchGroupUpdate,
  fetchGroupDelete,
  fetchGroupSort,
  fetchGroupQuery,
}