import dynamic from "dva/dynamic";

/**
 * dva路由动态加载方法
 */
export default (component: any) => dynamic(
  {
    app: "", // dva实例
    component, // 加载页面
    models: "" // 动态加载model
  } as any
) as any