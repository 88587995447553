import fetchApi from "utils/fetchApi";
import Tools from "utils/tools";

/**查询花名册列表 */
const fetchEmployeeList = (data: any) => fetchApi("get", Tools.appendParams("api/employee/manager/queryList", data))

/**查询花名册表头统计 */
const fetchEmployeeStatistics = (groupCode?: string) => fetchApi("get", `api/employee/manager/statistics?groupCode=${groupCode || ""}`)

/**花名册导出 */
const fetchEmployeeExport = (data: any) => fetchApi("post", "api/common/exportEmployee", data)

/**花名册全量导出 */
const fetchEmployeeExportAll = (data: any) => fetchApi("post", "api/common/exportEmployeeAll", data)

/**花名册提交前校验唯一性 */
const fetchEmployeeValidate = (data: any) => fetchApi("post", "api/employee/manager/addVerify", data)

/**花名册添加员工 */
const fetchEmployeeAdd = (data: any) => fetchApi("post", "api/employee/manager/add", data)

/**查询部门员工信息 */
const fetchGroupEmployee = (data: any) => fetchApi("post", "api/employee/queryEmployeeSimpleInfo", data || {})

/**获取花名册导入模板下载地址 */
const fetchTemplateUrl = () => fetchApi("get", "api/employee/manager/queryImportTemplate")

/**查询导入记录 */
const fetchImportHistory = (processType: any) => fetchApi("get", `api/employee/manager/queryImportList?processType=${processType}`)

/**花名册批量导入 */
const fetchExcelImport = (data: any) => fetchApi("post", "api/employee/manager/excelImport", data)

/**花名册办理离职 */
const fetchEmployeeLeaveOffice = (data: any) => fetchApi("post", "api/employee/manager/separate", data)

/**花名册邀请完善信息 */
const fetchEmployeeInvite = (data: any) => fetchApi("post", "api/employee/manager/sendForEmployeeModify", data)

/**花名册发起调动 */
const fetchEmployeeDoAdjust = (data: any) => fetchApi("post", "api/employeeOperate/doAdjust", data)



/**花名册详情-获取员工详情自定义字段 */
const fetchFieldsConfigGroups = (groupCode?: string) => fetchApi("post", `api/config/selectConfigCenterGroups?groupCode=${groupCode || ""}`)

/**花名册详情-获取个人信息、在职信息 */
const fetchEmployeeInfo = (employeeCode: string) => fetchApi("get", `api/employee/getEmployeeInfo/${employeeCode}`)

/**花名册详情-保存在职信息-校验 */
const fetchEmployeeJobValidate = (data: any) => fetchApi("post", "api/employee/validateSaveEmployeeOnTheJob", data)

/**花名册详情-保存在职信息 */
const fetchEmployeeJobUpdate = (data: any) => fetchApi("post", "api/employee/saveEmployeeOnTheJob", data)

/**花名册详情-保存个人信息-校验 */
const fetchEmployeePersonalValidate = (data: any) => fetchApi("post", "api/employee/validateSaveEmployeePersonal", data)

/**花名册详情-保存个人信息 */
const fetchEmployeePersonalUpdate = (data: any) => fetchApi("post", "api/employee/saveEmployeePersonal", data)


/**花名册详情-查询银行卡信息 */
const fetchEmployeeBankInfo = (employeeCode: string) => fetchApi("get", `api/employee/getEmployeeBank/${employeeCode}`)

/**花名册详情-保存银行卡信息-校验 */
const fetchEmployeeBankValidate = (data: any) => fetchApi("post", "api/employee/validateUpdateEmployeeBank", data)

/**花名册详情-修改银行卡信息 */
const fetchEmployeeBankUpdate = (data: any) => fetchApi("post", "api/employee/updateEmployeeBank", data)


/**花名册详情-获取教育经历信息 */
const fetchEducationHistory = (employCode: string) => fetchApi("get", `api/employee/query/EducationHistory/${employCode}`)

/**花名册详情-添加教育经历信息 */
const fetchEducationHistoryAdd = (data: string) => fetchApi("post", "api/employee/insert/EducationHistory", data)

/**花名册详情-校验教育经历信息 */
const fetchEducationHistoryValidate = (data: string) => fetchApi("post", "api/employee/validate/EducationHistory", data)

/**花名册详情-更新教育经历信息 */
const fetchEducationHistoryUpdate = (data: string) => fetchApi("post", "api/employee/update/EducationHistory", data)

/**花名册详情-删除教育经历信息 */
const fetchEducationHistoryDelete = (id: string) => fetchApi("get", `api/employee/delete/EducationHistory/${id}`)


/**花名册详情-获取紧急联系人 */
const fetchEmergencyUser = (employCode: string) => fetchApi("get", `api/employee/select/emergencyContactUser/${employCode}`)

/**花名册详情-添加紧急联系人 */
const fetchEmergencyUserAdd = (data: any) => fetchApi("post", "api/employee/insert/emergencyContactUser", data)

/**花名册详情-更新紧急联系人 */
const fetchEmergencyUserUpdate = (data: string) => fetchApi("post", "api/employee/update/emergencyContactUser", data)

/**花名册详情-校验紧急联系人 */
const fetchEmergencyUserValidate = (data: string) => fetchApi("post", "api/employee/valid/emergencyContactUser", data)

/**花名册详情-删除紧急联系人 */
const fetchEmergencyUserDelete = (id: string) => fetchApi("get", `api/employee/delete/emergencyContactUser/${id}`)


/**花名册详情-获取家庭成员 */
const fetchFamilyMember = (employCode: string) => fetchApi("get", `api/employee/select/familyMember/${employCode}`)

/**花名册详情-添加家庭成员 */
const fetchFamilyMemberAdd = (data: any) => fetchApi("post", "api/employee/insert/familyMember", data)

/**花名册详情-更新家庭成员 */
const fetchFamilyMemberUpdate = (data: string) => fetchApi("post", "api/employee/update/familyMember", data)

/**花名册详情-校验家庭成员 */
const fetchFamilyMemberValidate = (data: string) => fetchApi("post", "api/employee/valid/familyMember", data)

/**花名册详情-删除家庭成员 */
const fetchFamilyMemberDelete = (id: string) => fetchApi("get", `api/employee/delete/familyMember/${id}`)


/**花名册详情-获取工作经历 */
const fetchWorkHistory = (employCode: string) => fetchApi("get", `api/employee/select/workHistory/${employCode}`)

/**花名册详情-添加工作经历 */
const fetchWorkHistoryAdd = (data: any) => fetchApi("post", "api/employee/insert/workHistory", data)

/**花名册详情-更新工作经历 */
const fetchWorkHistoryUpdate = (data: string) => fetchApi("post", "api/employee/update/workHistory", data)

/**花名册详情-校验工作经历 */
const fetchWorkHistoryValidate = (data: string) => fetchApi("post", "api/employee/valid/workHistory", data)

/**花名册详情-删除工作经历 */
const fetchWorkHistoryDelete = (id: string) => fetchApi("get", `api/employee/delete/workHistory/${id}`)


/**花名册详情-获取合同记录 */
const fetchContractRecord = (employCode: string) => fetchApi("get", `api/contract/record/selectList/${employCode}`)

/**花名册详情-添加合同记录 */
const fetchContractRecordAdd = (data: any) => fetchApi("post", "api/contract/record/insert", data)

/**花名册详情-更新合同记录 */
const fetchContractRecordUpdate = (data: string) => fetchApi("post", "api/contract/record/update", data)

/**花名册详情-校验合同记录 */
const fetchContractRecordValidate = (data: string) => fetchApi("post", "api/contract/record/valid", data)

/**花名册详情-删除合同记录 */
const fetchContractRecordDelete = (id: string) => fetchApi("get", `api/contract/record/delete/${id}`)


/**花名册详情-获取材料附件 */
const fetchFileAttachment = (employCode: string) => fetchApi("get", `api/employee/file/select/${employCode}`)

/**花名册详情-上传材料附件 */
const fetchFileAttachmentAdd = (data: any) => fetchApi("post", "api/employee/file/insert", data)

/**花名册详情-重新上传材料附件 */
const fetchFileAttachmentUpdate = (data: string) => fetchApi("post", "api/employee/file/update", data)

/**花名册详情-删除材料附件 */
const fetchFileAttachmentDelete = (id: string) => fetchApi("get", `api/employee/file/delete/${id}`)


/**花名册详情-获取异动记录 */
const fetchTransferRecord = (employCode: string) => fetchApi("post", "api/employee/selectOperateList", employCode)

export {
  fetchEmployeeList,
  fetchEmployeeStatistics,
  fetchEmployeeExport,
  fetchEmployeeExportAll,
  fetchEmployeeValidate,
  fetchEmployeeAdd,
  fetchGroupEmployee,
  fetchTemplateUrl,
  fetchExcelImport,
  fetchImportHistory,
  fetchEmployeeLeaveOffice,
  fetchEmployeeInvite,
  fetchEmployeeDoAdjust,
  fetchFieldsConfigGroups,
  fetchEmployeeInfo,
  fetchEmployeeJobUpdate,
  fetchEmployeeJobValidate,
  fetchEmployeePersonalUpdate,
  fetchEmployeePersonalValidate,
  fetchEmployeeBankInfo,
  fetchEmployeeBankUpdate,
  fetchEmployeeBankValidate,
  fetchEducationHistory,
  fetchEducationHistoryAdd,
  fetchEducationHistoryUpdate,
  fetchEducationHistoryValidate,
  fetchEducationHistoryDelete,
  fetchEmergencyUser,
  fetchEmergencyUserAdd,
  fetchEmergencyUserUpdate,
  fetchEmergencyUserValidate,
  fetchEmergencyUserDelete,
  fetchFamilyMember,
  fetchFamilyMemberAdd,
  fetchFamilyMemberUpdate,
  fetchFamilyMemberValidate,
  fetchFamilyMemberDelete,
  fetchWorkHistory,
  fetchWorkHistoryAdd,
  fetchWorkHistoryUpdate,
  fetchWorkHistoryValidate,
  fetchWorkHistoryDelete,
  fetchContractRecord,
  fetchContractRecordAdd,
  fetchContractRecordUpdate,
  fetchContractRecordValidate,
  fetchContractRecordDelete,
  fetchFileAttachment,
  fetchFileAttachmentAdd,
  fetchFileAttachmentUpdate,
  fetchFileAttachmentDelete,
  fetchTransferRecord
}