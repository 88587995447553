import React from "react";
import { connect } from "dva";
import { Switch, Redirect } from "dva/router";
import { Layout } from "antd";
import { start, loadMicroApp, prefetchApps, registerMicroApps } from "qiankun";
import { APP_CODE, CID, ENABLE_MICROMODULE, LOGIN_URL, MOSS_PAGE, TOKEN } from "common/constants";
import { ICompany, IUser } from "common/types";
import { microApps } from "common/microApps";
import { VhMenu } from "msd/dist/vh-sider";
import images from "utils/images";
import VhMenuComp from "components/ms-common/vh-menu/VhMenu";
import VhHeader from "components/ms-common/vh-header/VhHeader";
import MsAuthRoute from "./MsAuthRoute";
import { ApplicationState } from "models";
import Tools from "utils/tools";
import "./MsLayout.scss";
import VhIconCommon from "components/ms-common/vh-icon-common";
import MsHeader from "components/ms-common/ms-header";

/**
 * state props
 *
 * @interface PropsFromState
 */
interface PropsFromState {
  userInfo: IUser;
  companyInfo: ICompany;
  menuList: VhMenu[];
  openKeys: any[];
}

interface PropsOwn {
  [key: string]: any;
}

interface StateOwn {
  menuList: any[];
  collapse: boolean;
  openCompany: boolean;
}


type AllProps = PropsFromState & PropsOwn;

// 关联state到组件props
const mapStateToProps = ({ app }: ApplicationState) => ({
  userInfo: app.userInfo,
  companyInfo: app.companyInfo,
  menuList: app.menuList,
  openKeys: app.openKeys
});

/**
 * 布局组件
 */
class MsLayout extends React.Component<AllProps, StateOwn> {

  /**
   * 菜单权限获取成功后更新路由列表
   *
   * @static
   * @param {AllProps} nextProps
   * @param {StateOwn} prevState
   * @returns
   */
  public static getDerivedStateFromProps(nextProps: AllProps, prevState: StateOwn) {
    if (nextProps.menuList !== prevState.menuList) {
      return {
        menuList: nextProps.menuList
      }
    }
    return null;
  }

  constructor(props: AllProps) {
    super(props);

    this.state = {
      menuList: [],
      collapse: false,
      openCompany: false
    }
  }

  public componentDidMount() {

    // 查询数据字典
    this.props.dispatch({ type: "app/queryCommonMap" })

    // 查询用户信息
    this.props.dispatch({ type: "app/queryUserInfo" })

    // 查询菜单列表
    this.props.dispatch({ type: "app/queryMenuList" })

    /**注册微应用 */
    registerMicroApps(microApps)

    /**是否加载微模块 */
    if(ENABLE_MICROMODULE) {
      // 预加载微模块系统
      prefetchApps([
        {
          name: "smarthr",
          entry: `//${ process.env.REACT_APP_ENV === "pro" ? "" : "pre" }micromodule.viphrm.com`,
          // entry: "//localhost:20010",
        },
      ]);
      // 手动加载微模块系统中的各模块
      loadMicroApp({
        name: "smarthr",
        entry: `//${ process.env.REACT_APP_ENV === "pro" ? "" : "pre" }micromodule.viphrm.com`,
        // entry: "//localhost:20010",
        container: "#headerView",
        props: {
          token: TOKEN,
          cid: CID,
          microModule: location.href.indexOf("smarthr.viphrm.com") > -1 ? "ms-header": "ms-smarthr-header",
          // microModule: "ms-smarthr-header",
          // microModuleHost: "http://localhost:20010",
          microModuleHost: `https://${ process.env.REACT_APP_ENV === "pro" ? "" : "pre" }micromodule.viphrm.com`,
          sourceAppCode: APP_CODE,
          catchMicroAppHandle: this.catchMicroAppHandle
        }
      })

      // 启动qiankun
      start()
    }
    
  }

  /*header的跳转 */
  public catchMicroAppHandle = (type: string) => {
    switch(type) {
      case "navigatorTo" :{
        this.props.history.push("/shr/organization/structure")
        break;
      }
      case "logout" :{
        this.onLogout();
        break;
      }
    }
  }

  /**
   * 保存常用应用
   *
   * @memberof MsLayout
   */
    public onSave = (ids: string[]) => {
      //
    }

  /**
   * 登出
   */
  public onLogout = () => {
    console.warn("用户退出")
    this.props.dispatch({ type: "app/logout" }).then(() => {
      Tools.delCookie("ms_member_token")
      Tools.delCookie("ms_cid")
      Tools.delCookie("ms_expire")
      Tools.delCookie("ms_appCode")
      window.location.href = LOGIN_URL
    })
  }

  // 渲染
  public render() {
    const { menuList = [], collapse } = this.state;
    const { companyInfo, userInfo, openKeys } = this.props
    const isMicroApp = ["/shr/salaryConfigManager", "/shr/paySalaryManager", "/shr/eContractComapny"].some(key => location.pathname.startsWith(key))
    const iconButton = <VhIconCommon type=" " />
    return (
      <Layout className="smartHR-content">
        {/* <VhHeader
            picture={userInfo.picture}
            name={userInfo.realName}
            companyName={companyInfo.name}
            logout={this.onLogout}
          /> */}
          {/* <MsHeader 
          companyInfo = { this.props.companyInfo }
          appList={ this.props.appList } 
          selectedApp={ APP_CODE} 
          myCompanyList = { this.props.myCompanyList }
          onSave={ this.onSave } 
          onLogout={ this.onLogout } 
          name={ "this.props.user.realName" } 
          userInfo = { this.props.user }
          picture={ "" } 
          // catchMicroAppHandle = { this.catchMicroAppHandle }
          homePage={ MOSS_PAGE } /> */}
        <div id="headerView"/>
        <Layout>
          <VhMenuComp
            title="SmartHR"
            menu={menuList}
            realName={userInfo.realName}
            name={companyInfo.name}
            openKeysList={openKeys}
            collapse={collapse}
            companyLogo={images.logo}
            companyCid={companyInfo.companyId}
          />
          <Layout.Content>
            <div id="childView" style={{ display: isMicroApp ? "block" : "none" }} />
            {!isMicroApp && menuList && menuList.length > 0 && (
              <Switch>
                <MsAuthRoute menuList={menuList} />
                <Redirect to="/" />
              </Switch>
            )}
          </Layout.Content>
        </Layout>
      </Layout>
    )
  }
}

export default connect(mapStateToProps)(MsLayout)
