import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { VhTools } from "msd";
import { fetchEmployeePassList, fetchEmployeePassStatictics, fetchConfirmPass, fetchUpdatePassDate, fetchCancelPass, fetchExportList } from "services/employeePass";
import { modelExtend } from "./common";
import { StatisticsDataType } from "components/ms-statistics/MsStatistics";


// 转正管理列表类型
export interface EmployeePassListTypes {
  [x: string]: any;
  /**姓名 */
  userName: string;
  /** 转正申请状态 */
  applyRegularStatus: number,
  /** 员工信息code */
  employeeCode: string
  /** 雇员类型 1-全职，2-实习，3-劳务，4-外包，5-其他 */
  employeeType: number
  /** 入职日期 */
  enrollmentDate: string
  /** 部门CODE */
  groupCodes: string,
  /** 部门名称 */
  groupNames: string
  /** 上级CODE */
  leaderCode: string
  /** 上级名称 */
  leaderName: string
  /** 手机号 */
  mobile: string
  /** 职位CODE */
  postCode: string
  /** 职位名称 */
  postName: string
  /** 试用期（月） */
  probationPeriod: number
  /** 职级CODE */
  rankCode: string
  /** 职级 */
  rankName: string
  /** 转正日期 */
  regularDate: string
  /** 转正状态：21-待转正，22-已转正，23-取消转正 */
  regularStatus: number
  /** 会员域ID */
  userId: number
  /** 工作地点CODE */
  workPlaceCode: string
  /** 工作地点 */
  workPlaceName: string
}

export interface EmployeePassSearchParamsType {
  [x: string]: any;
  /**模糊查询姓名或手机号 */
  searchCriteria: string;
}


/**转正管理模块State对象 */
export interface EmployeePassState {
  /** 统计数据 */
  statisticsData: StatisticsDataType[],
  /** 列表数据 */
  employeePassList: EmployeePassListTypes[];
  /** 列表分页数据 */
  pageData: IPagination;
  /**选中卡片 */
  cardIndex: number;
  /**展开、收起 */
  isExpanded: boolean;
  /**查询参数 */
  employeePassSearchParams: EmployeePassSearchParamsType;
}

/**转正管理模块初始化State */
export const initEmployeePassState: EmployeePassState = {
  statisticsData: [
    { label: "全部待转正", value: 0, searchType: "1" },
    { label: "超期未转正", value: 0, searchType: "2" },
    { label: "本月待转正", value: 0, searchType: "3" },
    { label: "下月待转正", value: 0, searchType: "4" },
  ],
  employeePassList: [],
  pageData: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  cardIndex: 0,
  isExpanded: false,
  employeePassSearchParams: {
    searchCriteria: "",
    regularType: "1",
    employeeType: [],
    leaderCode: [],
    groupCodes: [],
    rankCode: [],
    postCode: [],
    workPlaceCode: [],
    contractCompanyCode: [],
    startEnrollmentDate: "",
    endEnrollmentDate: "",
    startRegularDate: "",
    endRegularDate: "",
    applyRegularStatus: [],
    pageIndex: VhTools.getInitPagination().current,
    pageSize: VhTools.getInitPagination().pageSize,
  }
}

const namespace: string = "employeePass";

export default modelExtend<EmployeePassState>({
  namespace,
  state: initEmployeePassState,
  effects: {
    /** 查询转正人员统计数据 */
    *queryStatisticsData(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeePassStatictics)
      if (res?.result === 0) {
        const data = [
          { label: "全部待转正", value: res.data.extra?.allRegular || 0, searchType: "1" },
          { label: "超期未转正", value: res.data.extra?.exceedTimeRegular || 0, searchType: "2" },
          { label: "本月待转正", value: res.data.extra?.currentMonthRegular || 0, searchType: "3" },
          { label: "下月待转正", value: res.data.extra?.nextMonthallRegular || 0, searchType: "4" },
        ]
        yield put({
          type: "updateState",
          payload: {
            statisticsData: data,
          }
        })
      }
    },
    /**查询转正人员列表数据 */
    *queryEmployeePassList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchEmployeePassList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            employeePassSearchParams: { ...payload },
            employeePassList: res.data.records || [],
            pageData: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },
    /** 确认转正 */
    *confirmPass({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchConfirmPass, payload)
      return res
    },
    /** 跳转转正日期 */
    *updatePassDate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchUpdatePassDate, payload)
      return res
    },
    /** 撤销转正 */
    *cancelPass({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchCancelPass, payload)
      return res
    },
    /** 列表数据导出 */
    *exportList({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchExportList, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
  }
})
