import Tools from "./tools"

const images: any = {
  back: "https://uploadv2.viphrm.com/filemanager/preview/?path=288572/202307/a722b46316b9476592c0272c210ef8d7.png",
  logo: "https://uploadv2.viphrm.com/filemanager/preview/?path=288572/202307/14aa9f3fbc8349309b1ef015d08b2d38.png",
  table: "https://uploadv2.viphrm.com/filemanager/preview/?path=288572/202307/1f70aacab42840f2af49a9347b1d7ed6.png"
}

const getLogoBase64 = async () => {
  const logoBase64 = await Tools.getBase64(images.logo)
  images.logoBase64 = logoBase64;
}

getLogoBase64()

export default images