import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { VhTools } from "msd";
import {
  fetchPostList,
  fetchPostAdd,
  fetchPostDelete,
  fetchPostUpdate,
  fetchRankList,
  fetchRankUpdate,
  fetchRankDelete,
  fetchRankAdd,
  fetchWorkPlaceList,
  fetchWorkPlaceUpdate,
  fetchWorkPlaceDelete,
  fetchWorkPlaceAdd,
  fetchPostValidate,
  fetchRankValidate,
  fetchWorkPlaceValidate
} from "services/orginazation";
import { modelExtend } from "./common";


// 岗位列表类型
export interface PostListTypes {
  /**岗位编码 */
  postCode: string;
  /**岗位名称 */
  postName: string;
  /**描述 */
  remark: string;
  /**公司id */
  companyId: number;
  /**创建人id */
  creatorId: number;
  /**创建人名称*/
  creatorName: string;
  /**创建时间 */
  createTime: string
  /**更新人id */
  modifierId: number;
  /**更新人名称 */
  modifierName: string;
  /**更新时间 */
  modifyTime: string;
}

// 职级列表类型
export interface RankListTypes {
  /**职级编码 */
  rankCode: string;
  /**职位名称 */
  rankName: string;
  /**描述 */
  remark: string;
  /**公司id */
  companyId: number;
  /**创建人id */
  creatorId: number;
  /**创建人名称*/
  creatorName: string;
  /**创建时间 */
  createTime: string
  /**更新人id */
  modifierId: number;
  /**更新人名称 */
  modifierName: string;
  /**更新时间 */
  modifyTime: string;
}

// 工作地点列表类型
export interface WorkPlaceListTypes {
  /**工作地点编码 */
  workPlaceCode: string;
  /**工作地点名称 */
  workPlaceName: string;
  /**省份编码 */
  provinceCode: string;
  /**省份名称 */
  provinceName: string;
  /**城市编码 */
  cityCode: string;
  /**城市名称 */
  cityName: string;
  /**地址 */
  address: string;
  /**创建人id */
  creatorId: number;
  /**创建人名称*/
  creatorName: string;
  /**创建时间 */
  createTime: string
  /**更新人id */
  modifierId: number;
  /**更新人名称 */
  modifierName: string;
  /**更新时间 */
  modifyTime: string;
  /**在职人数 */
  employeeNum: number;
}

export interface OrginazationState {
  postList: PostListTypes[];
  postListPagination: IPagination;
  rankList: RankListTypes[];
  rankListPagination: IPagination;
  workPlaceList: WorkPlaceListTypes[];
  workPlaceListPagination: IPagination;
}


const initListPagination: IPagination = {
  pageSize: VhTools.getInitPagination().pageSize,
  current: VhTools.getInitPagination().current,
  total: 0
}

export const initOrginazationState: OrginazationState = {
  postList: [],
  postListPagination: initListPagination,
  rankList: [],
  rankListPagination: initListPagination,
  workPlaceList: [],
  workPlaceListPagination: initListPagination,
}

const namespace: string = "orginazation";

export default modelExtend<OrginazationState>({
  namespace,
  state: initOrginazationState,
  effects: {
    /**查询岗位列表 */
    *queryPostList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const { fetchMaxData } = payload;
      const res: any = yield call(fetchPostList, payload);
      if (fetchMaxData) { return res; }
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            postList: res.data.records || [],
            postListPagination: res.data.pageIndex ? {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            } : initListPagination
          }
        })
      }
    },
    /**添加岗位 */
    *doPostAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchPostAdd, payload);
      return res
    },
    /**更新岗位 */
    *doPostUpdate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchPostUpdate, payload);
      return res
    },
    /**校验岗位重复 */
    *doPostValidate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchPostValidate, payload);
      return res
    },
    /**岗位删除 */
    *doPostDelete({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchPostDelete, payload);
      return res
    },
    /**查询职级列表 */
    *queryRankList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const { fetchMaxData } = payload;
      const res: any = yield call(fetchRankList, payload);
      if (fetchMaxData) { return res }
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            rankList: res.data.records || [],
            rankListPagination: res.data.pageIndex ? {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            } : initListPagination
          }
        })
      }
    },
    /**添加职级 */
    *doRankAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchRankAdd, payload);
      return res
    },
    /**更新职级 */
    *doRankUpdate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchRankUpdate, payload);
      return res
    },
    /**校验职级重复 */
    *doRankValidate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchRankValidate, payload);
      return res
    },
    /**删除职级 */
    *doRankDelete({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchRankDelete, payload);
      return res
    },
    /**查询工作地点列表 */
    *queryWorkPlaceList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const { fetchMaxData } = payload;
      const res: any = yield call(fetchWorkPlaceList, payload);
      if (fetchMaxData) { return res; }
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            workPlaceList: res.data.records || [],
            workPlaceListPagination: res.data.pageIndex ? {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            } : initListPagination
          }
        })
      }
    },
    /**添加工作地点 */
    *doWorkPlaceAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkPlaceAdd, payload);
      return res
    },
    /**更新工作地点 */
    *doWorkPlaceUpdate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkPlaceUpdate, payload);
      return res
    },
    /**校验工作地点重复 */
    *doWorkPlaceValidate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkPlaceValidate, payload);
      return res
    },
    /**删除工作地点 */
    *doWorkPlaceDelete({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWorkPlaceDelete, payload);
      return res
    },
  }
})
