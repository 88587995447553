import * as React from "react";
import home from "./home.png";
import "./MsHome.scss";

/**
 * 欢迎页
 *
 * @class MsHome
 * @extends {React.Component}
 */
class MsHome extends React.Component {
  public render() {
    return (
      <div className="ms-home">
        <p>欢迎进入{ location.href.indexOf("smarthr.viphrm.com") > -1 ? "组织人事" : "SmartHR" }系统</p>
        <p>请继续点击左侧导航栏，开始使用吧</p>
        <img alt="home" src={home} />
      </div>
    )
  }
}

export default MsHome;
