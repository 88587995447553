import _modelExtend from "dva-model-extend"
import { VhTools } from "msd";
import { DvaModel } from "../common/dvatypes";

const commonModel = {
    reducers: {
        updateState(state: any, { payload }: any) {
            return {
                ...state,
                ...payload,
            }
        },
        error(state: any, { payload }: any) {
            return {
                ...state,
                error: payload,
            }
        },
    },
}

const modelExtend = <T>(model: DvaModel<T>): DvaModel<T> => _modelExtend(commonModel, model);

const pageModel = {
    state: {
        list: [],
        pagination: {
            pageSize: VhTools.getInitPagination().pageSize,
            current: VhTools.getInitPagination().current,
            total: 0
        },
    },

    reducers: {
        querySuccess(state: any, { payload }: any) {
            const { list, pagination } = payload
            return {
                ...state,
                list,
                pagination: {
                    ...state.pagination,
                    ...pagination,
                },
            }
        },
        updateState(state: any, { payload }: any) {
            return {
                ...state,
                ...payload,
            }
        },
        error(state: any, { payload }: any) {
            return {
                ...state,
                error: payload,
            }
        },
    },

}

const pageModelExtend = <T>(model: DvaModel<T>): DvaModel<T> => _modelExtend(pageModel, model);

export {
    modelExtend,
    pageModelExtend,
    commonModel,
    pageModel,
}