import React from "react";
import { Modal, Form, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { ICompany } from "common/types";

interface PropsOwn extends FormComponentProps {
  /** 数据源 */
  dataSource: ICompany[];
  /** 确定 */
  onOk: (companyId: string, roleType: number) => void;
  /** 关闭 */
  onClose: () => void;
}

/**
 * 登录选择公司弹框
 */
const MsCompanyPickModal = (props: PropsOwn) => {

  const { dataSource, form: { getFieldDecorator, validateFields }, onOk, onClose } = props

  /**权限 */
  const roleRef = React.useRef<any>(dataSource?.length > 0 ? dataSource[0].roleType : undefined)


  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  }

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        onOk(values.companyId, roleRef.current)
      }
    })
  }

  /**选择公司 */
  const onSelectCompany = (companyId: string) => {
    const company = dataSource.find((item: ICompany) => item.companyId === companyId)
    if (company) {
      roleRef.current = company.roleType
    }
  }

  return (
    <Modal
      title="登录空间"
      visible={true}
      centered={true}
      destroyOnClose={true}
      onOk={handleSubmit}
      onCancel={onClose}
    >
      <Form {...formItemLayout} colon={false}>
        <Form.Item className="no-explain" label="公司名称">
          {getFieldDecorator("companyId", {
            initialValue: dataSource?.length > 0 ? dataSource[0].companyId : undefined
          })(
            <Select placeholder="选择登录公司" onSelect={onSelectCompany}>
              {dataSource.map((item: ICompany) => (
                <Select.Option key={item.companyId} value={item.companyId}>{item.name}</Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Form.create<PropsOwn>()(MsCompanyPickModal)