import Tools from "utils/tools";

import { ModalPositiion } from "./types";

export const env = process.env.REACT_APP_ENV

export const LOGIN_URL = "/login";

export const MOSS_LOGIN_URL = `${ process.env.REACT_APP_ENV === "pro" ? "https" : "http" }://${ process.env.REACT_APP_ENV !== "pro" ? (process.env.REACT_APP_ENV === "test" ? "dv" : (process.env.REACT_APP_ENV === "pre" ? "test" : "dev")) : "" }login.viphrm.com${ process.env.REACT_APP_ENV === "dev" ? ":8088" : "" }`;

export const HOME_PAGE = `http://${env !== "pro" ? env : ""}moss.viphrm.com`;

export const ERROR_MESSAGE = "系统维护中，请联系管理员。";

export const EXPIRE_MESSAGE = "登录已过期，请重新登陆！";

export const LOADING_MESSAGE = "正在查询，请稍后...";

/**应用编码 */
export let APP_CODE = Tools.getCookie("ms_appCode") || Tools.getUrlParam("appCode") || "1901";

/**判断当前需要写cookie的主域 */
export const YU_MING = APP_CODE === "1901" ? "smarthr.cn" : "viphrm.com";

/**设置页面title */
document.title = location.href.indexOf("smarthr.viphrm.com") > -1 ? "组织人事" : "SmartHR";

const onGetTokenFromUrl = () => {
  const token = Tools.getUrlParam("token") || Tools.getUrlParam("member_token");
  const cid = Tools.getUrlParam("cid") || Tools.getUrlParam("Mscid");
  if (token && cid) {
    const date = new Date();
    const expiresDays = 7;
    date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000);
    Tools.setCookie("ms_member_token", token, date, YU_MING);
    Tools.setCookie("ms_cid", cid, date, YU_MING);
    Tools.setCookie("ms_expire", date.toString(), date, YU_MING);
    window.location.href = `${window.location.origin}${window.location.pathname}`;
  }
  return { token, cid }
}

export const CID = onGetTokenFromUrl().token || Tools.getCookie("ms_cid") || "288572";

export const TOKEN = onGetTokenFromUrl().cid || Tools.getCookie("ms_member_token") || 
"209157.eyJhbGciOiJIUzI1NiJ9.eyJhcHBpZCI6IjEzMDAxIiwiZXhwIjoxNzAyNDQ3ODg1LCJ1c2VyaWQiOiIyMDkxNTcifQ.vmHq5nxWcHzeG46RVp2Ht3XBstr7ouXhKkOAsnUGOCw";

/**pdf预览 */
export const pdfUrl = `${env === "dev" ? "http://192.168.1.181:9077" : (`https://${env === "pro" ? "" : env}uploadv2.viphrm.com`)}/filemanager/viewer.html?file=`;

export const preAliUrl = env === "dev" ? "http://192.168.1.181:9077" : env === "pre" ? "https://preuploadv2.viphrm.com" : "https://uploadv2.viphrm.com";

/**excel下载 */
export const excelUrl = env === "pro" ? "https://salaryslipmanager.viphrm.com" : env === "pre" ? "http://presalarymanager.viphrm.com" : "http://devsalarymanager.viphrm.com"

/**附件路径 */
export const aliUrl = preAliUrl + "/filemanager/preview/?path=";

/**延迟时长 */
export const delayTimer = 1000;

export const TAX_CRITIAL_MAX_VALUE_LIMIT = 999999999;

export const acceptPic = "jpg、jpeg、png";
export const ONE_M = 1048576;
export const limitSize = 1;
export const fileUploadUrl = "/filemanager/picupload.jsx";
export const onlyOneAttachmentCode = ["id_card_photo_front", "id_card_photo_back", "personal_photo"];  // 只可以上传一张图片的附件code列表


// appCode写入cookie;
if (Tools.getUrlParam("appCode")) {
  const date = new Date();
  const expiresDays = 7;
  date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000);
  Tools.setCookie("ms_appCode", Tools.getUrlParam("appCode"), date, YU_MING);
}

/**设置应用编码 */
export const setAppCode = (code: string) => APP_CODE = code;


/**外链来源对应的AppCode */
export const SOURCE_APPCODE = Tools.getUrlParam("sourceAppCode") || "";

/**批量导入数量上限 */
export const maxImportDataRows = 500;

/**通用modal尺寸 */
export const MODAL_POSITION: ModalPositiion = {
  top: 50,
  height: document.body.offsetHeight - 100
}

/**
 * 用于控制fetch请求
 * @abstract 因IE不支持AbortController API，故无法实现控制，需考虑其他方案 
 * @link url https://caniuse.com/?search=AbortController
 */
export const controller: any = {signal: null};
// export const setController = () => {
//   controller = new AbortController();
// }

/**是否开启微模块 */
export const ENABLE_MICROMODULE = true;

/**水印透明度 */
export const WATERREMARK_OPACITY = 0.1;

/**运营端、微知端落脚页 */
export const MOSS_PAGE = `http://${ process.env.REACT_APP_ENV !== "pro" ? process.env.REACT_APP_ENV : "" }moss.viphrm.com`;