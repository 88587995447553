import fetchApi from "utils/fetchApi";

/**获取数据字典 */
const fetchCommonMap = () => fetchApi("get", "api/common/queryExtraMetaMapList")

/**获取省份、城市树形结构 */
const fetchCommonTree = (data: any) => fetchApi("post", "api/common/dataDict/tree", data)

/**获取省份、城市树形结构 */
const fetchCityList = () => fetchApi("post", "api/common/cityTwon/tree")

/**获取可使用应用列表 */
const fetchAppList = () => fetchApi("get", "permissionServer/user/center/app/list")

/**查询应用可访问菜单配置 */
const fetchAppMenuList = (appId: string) => fetchApi("get", `permissionServer/user/leftnavgation/${appId}`)

/**查询应用信息 */
const fetchAppInfo = (appId: string) => fetchApi("get", `permissionServer/app/${appId}`)

/**退出登录 */
const fetchLogout = () => fetchApi("get", "api/login/logout")

/**密码登录 */
const fetchLogin = (data: any) => fetchApi("post", "api/login/byPassword", data)

/**获取用户信息 */
const fetchUserInfo = () => fetchApi("get", "home-server/auth/userAndCompany")

/**获取公司列表 */
const fetchCompanyList = () => fetchApi("get", "home-server/company/list")

/**查询表头所有字段 */
const fetchAllColumnsList = (tableCode: string) => fetchApi("get", `api/sys/table/view/column/queryList?tableCode=${tableCode}`)

/**新增/修改个人表头配置 */
const fetchColumnSettingSave = (data: any) => fetchApi("post", "api/table/view/settings/save", data)

/**获取用户设置的表头 */
const fetchColumnSettingList = (tableCode: string) => fetchApi("get", `api/table/view/settings/queryList?tableCode=${tableCode}`)

/** 导出进度查询 */
const fetchExportRate = (taskId: string) => fetchApi("get", `api/common/rate/${taskId}`)

export {
    fetchCommonMap,
    fetchCommonTree,
    fetchCityList,
    fetchAppList,
    fetchAppMenuList,
    fetchLogin,
    fetchLogout,
    fetchAppInfo,
    fetchUserInfo,
    fetchCompanyList,
    fetchAllColumnsList,
    fetchColumnSettingSave,
    fetchColumnSettingList,
    fetchExportRate
}