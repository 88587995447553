
import { CommonTreeListType, ICompany, IMapType, IUser } from "common/types";
import { modelExtend } from "./common";
import { ReduxSagaEffects, ReduxAction } from "common/dvatypes";
import {
    fetchCommonMap,
    fetchLogout,
    fetchLogin,
    fetchUserInfo,
    fetchAppList,
    fetchCommonTree,
    fetchAllColumnsList,
    fetchColumnSettingList,
    fetchColumnSettingSave,
    fetchExportRate,
    fetchCompanyList,
    fetchAppMenuList,
    fetchAppInfo
} from "services/app"
import { VhMenu } from "msd/dist/vh-sider";
import { APP_CODE, controller, setAppCode } from "common/constants";
import { getMicroAppData, microAppCodes } from "common/microApps";

export interface Menu {
    /**id */
    id: string;
    /**分组名称 */
    groupName: string;
    /**分组图标 */
    groupIconName: string;
    /**功能名称 */
    functionName: string;
    /**功能编码 */
    functionCode: string;
    /**功能图标 */
    iconName: string;
    /**访问地址 */
    visitURL: string;
    externalUrl?: string
}


/**
 * 数据字典类型
 */
export interface CommonDictType {
    [key: string]: IMapType[];
}

export interface ExportRateType {
    showRate: boolean;
    rate: number;
    isNoCancel?: boolean;
}


// 表头所有字段
export interface TableFieldsType {
    /**页面表code */
    tableCode: string;
    /**ID */
    id: number;
    /**取消勾选：0-不可取消，1-可取消 */
    cancelSelect: number;
    /**列名称 */
    columnName: string;
    /**列编码 */
    columCode: string;
    /**是否默认字段： 0-否，1-是 */
    isDefault: number;
    /**排序 */
    sort: number;
}

// 用户设置的表头
export interface CustomColumnSettingType {
    companyId: number;
    creatorId: number;
    creatorName: string;
    createTime: number;
    modifierId: number;
    modifierName: string;
    modifyTime: number;
    id: number;
    employeeCode: string;
    tableCode: string;
    settings: string;
}

export interface AppState {
    /** 展开收起 */
    collapse: boolean;
    /**登录用户 */
    userInfo: IUser;
    /**公司信息 */
    companyInfo: ICompany;
    /**公司列表 */
    companyList: ICompany[];
    /**数据字典 */
    commonMap: CommonDictType;
    /**菜单列表 */
    menuList: VhMenu[];
    /**展开菜单项 */
    openKeys: any[];
    /**省、市树形列表 */
    provinceTreeList: CommonTreeListType[];
    /**导出进度 */
    exportRate: ExportRateType;
}

const initialState: AppState = {
    collapse: false,
    userInfo: {
        userId: "",
        realName: "",
        picture: "",
        gender: "",
        email: "",
        emailActive: "",
        mobile: "",
        mobileActive: ""
    },
    companyInfo: {
        companyId: "",
        companyType: "",
        englishName: "",
        shortName: "",
        companyLogo: "",
        name: "",
        userId: "",
        address: "",
        statusFlag: "",
        roleType: 1
    },
    companyList: [],
    menuList: [],
    commonMap: {},
    openKeys: [],
    provinceTreeList: [],
    exportRate: {
        showRate: false,
        rate: 0
    }
}

const namespace: string = "app";

const delay = (time: number) => new Promise((resolve: any) => setTimeout(resolve, time))

export default modelExtend<AppState>({
    namespace,
    state: initialState,
    effects: {
        /**登录 */
        *login({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
            const res: any = yield call(fetchLogin, payload);
            return res
        },
        /**查询用户信息 */
        *queryUserInfo(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const res: any = yield call(fetchUserInfo)
            if (res?.result === 0 && res?.data) {
                yield put({
                    type: "updateState",
                    payload: {
                        userInfo: res.data.extra,
                        companyInfo: res.data.extra?.company ?? initialState.companyInfo
                    }
                })

                // 查询省份、城市树形
                yield put({ type: "queryProvinceTree" })
            }
        },
        /**查询公司列表 */
        *queryCompanyList(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const res: any = yield call(fetchCompanyList)
            if (res?.result === 0 && res?.data) {
                yield put({
                    type: "updateState",
                    payload: {
                        companyList: res.data.records || [],
                    },
                })
            }
        },
        /**获取数据字典 */
        *queryCommonMap(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const res: any = yield call(fetchCommonMap)
            if (res?.result === 0 && res?.data) {
                yield put({
                    type: "updateState",
                    payload: {
                        commonMap: res.data.extra
                    }
                })
            }
        },
        /**获取菜单列表 */
        *queryMenuList(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const res: any = yield call(fetchAppList)
            if (res?.result === 0 && res?.data) {
                const appList = res.data.records || []
                const menuList: VhMenu[] = [] // 主应用菜单列表
                const microAppMenuList: VhMenu[] = []   // 微应用菜单列表
                yield call(async () => {
                    for (const app of appList) {
                        if (APP_CODE === app.appCode) {
                            console.log(11111111)
                            // const appRes: any = await fetchAppInfo(app.appId)
                            // console.log("res", res)
                            // if (appRes?.result === 0) {
                            //     // applyTerminal 为应用的权限信息，1为企业端，2为机构，3为微知端，10为易薪宝，11为工薪记，多个配置的时候用逗号分隔
                            //     const terminals = appRes.applyTerminal.split(",") || []
                            //     if (!terminals.includes("1")) {
                            //         setAppCode("1234")
                            //     }
                            // }
                            const response: any = await fetchAppMenuList(app.appId)
                            const menuData = response.data.records || []
                            for (const menu of menuData) {
                                if (menu.groupName) {
                                    const groupIndex = menuList.findIndex(group => group.groupName === menu.groupName)
                                    if (groupIndex > -1) {
                                        (menuList[groupIndex].children as VhMenu[]).push(menu)
                                    } else {
                                        menuList.push({
                                            functionCode: "",
                                            functionName: menu.groupName || "",
                                            groupIconName: menu.groupIconName,
                                            groupName: menu.groupName,
                                            iconName: "",
                                            id: undefined,
                                            visitURL: "",
                                            children: [menu]
                                        })
                                    }
                                } else {
                                    menuList.push(menu)
                                }
                            }
                        } else if (microAppCodes.includes(app.appCode) && APP_CODE === "1901") {
                            console.log(222222222, app)
                            const { routerPrefix, icon } = getMicroAppData(app.appCode)
                            const params: VhMenu = {
                                functionCode: app.appCode,
                                functionName: app.appName === "合同管理" ? "电子合同" : app.appName,
                                groupIconName: icon,
                                groupName: app.appName === "合同管理" ? "电子合同" : app.appName,
                                iconName: "",
                                id: +app.appId,
                                visitURL: "/shr" + routerPrefix,
                                children: []
                            }
                            const response: any = await fetchAppMenuList(app.appId)
                            if (response?.result === 0 && response.data.records.length > 0) {
                                const list = response.data.records || []
                                for (const item of list) {
                                    const childParams = {
                                        ...item,
                                        functionName: item.functionName === "合同管理" ? "电子合同" : item.functionName,
                                        visitURL: "/shr" + routerPrefix + item.visitURL,
                                    }
                                    params.children?.push(childParams)
                                }
                            }

                            microAppMenuList.push(params)
                        }
                    }
                })
                yield put({
                    type: "updateState",
                    payload: {
                        menuList: [...menuList, ...microAppMenuList]
                        // menuList: [...menuList] // 暂时不显示外联应用
                    }
                })
            }
        },
        /**获取省市树形 */
        * queryProvinceTree(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const data = {
                params: [{ dataTypeCode: "common.district", level: 2 }]
            }
            const res: any = yield call(fetchCommonTree, data)
            if (res?.result === 0 && res.data?.records?.length > 0) {
                yield put({
                    type: "updateState",
                    payload: {
                        provinceTreeList: res.data.records[0]
                    }
                })
            }
        },
        /**获取导出进度 */
        * queryExportRate({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const { stopDownload, taskId } = payload
            /**如果取消导出，则不显示导出modal */
            if (stopDownload) {
                yield put({
                    type: "updateState",
                    payload: { exportRate: { showRate: false, rate: 0 } }
                })
            } else {
                const res: any = yield call(fetchExportRate, taskId)
                if (res?.result === 0 && res?.data) {
                    if (res.data.rate < 100) {
                        yield call(delay, 1000)
                        yield put({
                            type: "updateState",
                            payload: { exportRate: { showRate: true, rate: res.data.rate } }
                        })
                        // const ctrl = controller;
                        // console.log("取消导出：", ctrl.signal.aborted);
                        // if (ctrl.signal.aborted) {
                        //     yield put({
                        //         type: "updateState",
                        //         payload: { exportRate: { showRate: false, rate: 0 } }
                        //     })
                        // } else {
                        //     yield put({ type: "queryExportRate", payload, callback })
                        // }
                        yield put({ type: "queryExportRate", payload, callback })
                    } else {
                        yield put({
                            type: "updateState",
                            payload: { exportRate: { showRate: true, rate: res.data.rate } }
                        })
                        yield call(delay, 1000)
                        yield put({
                            type: "updateState",
                            payload: { exportRate: { showRate: false, rate: 0 } }
                        })
                        if (callback) {
                            callback(res.data.downloadUrl)
                        }
                    }
                }

            }
        },
        /**左侧菜单展开收起 */
        * toggleCollpase(_action: ReduxAction, { put, select }: ReduxSagaEffects): any {
            const collapse = yield select(_ => _.app.collapse)
            yield put({
                type: "updateState",
                payload: {
                    collapse: !collapse
                }
            })
        },
        /**【表格列设置】查询表头所有字段 */
        * queryAllColumnsList({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
            const res: any = yield call(fetchAllColumnsList, payload);
            return res
        },
        /**【表格列设置】获取用户设置的表头 */
        * queryColumnSettingList({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
            const res: any = yield call(fetchColumnSettingList, payload);
            return res
        },
        /**【表格列设置】新增/修改个人表头配置 */
        * doColumnSettingUpdate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
            const res: any = yield call(fetchColumnSettingSave, payload);
            return res
        },
        /**退出登录 */
        * logout(_action: ReduxAction, { call }: ReduxSagaEffects): any {
            const res: any = yield call(fetchLogout)
            return res
        }
    }
})
