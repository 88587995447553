import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { VhTools } from "msd";
import {
  fetchWaitQuitList,
  fetchLeaveOfficeAdd,
  fetchLeaveOfficeRecovery,
  fetchLeaveOfficeCancel,
  fetchLeaveOfficeConfirm,
  fetchLeaveOfficeAdjust,
  fetchStatistics,
  fetchQuitList,
  fetchLeaveOfficeReEntry,
  fetchWaitQuitExport,
  fetchQuitExport,
  fetchLeaveOfficeValidateReEntry
} from "services/leaveOffice";
import { modelExtend } from "./common";


/**待离职列表类型 */
export interface LeaveOfficeListTypes {
  [x: string]: any;
  /**待离职名称 */
  postName: string;
  /**主键 */
  employeeInfoId: number;
}

/**已取消离职列表类型 */
export interface CancelEntryListTypes {
  [x: string]: any;

}

/**待离职查询入参 */
export interface SearchParamType {
  [x: string]: any;
  /**模糊查询姓名或手机号 */
  searchCriteria: string;
  /**是否查询小于今日数据 1是 0否 */
  isQueryLessToday?: number;
}

/**已取消离职查询入参 */
export interface CancelEntrySearchParamType {
  [x: string]: any;
  /**模糊查询姓名或手机号 */
  searchCriteria: string;
}

/**模块私有参数 */
export interface ModuleParamType {
  /**是否展开 */
  isExpanded?: boolean;
  /**当前所处的tab */
  currentTab?: string;
}

export interface WaitQuitModuleParamType extends ModuleParamType {
  /**当前card */
  curCard: string;
}


/**离职管理模块State对象 */
export interface LeaveOfficeState {
  /**离职模块参数 */
  leaveOfficeModuleParams: ModuleParamType;
  /**待离职列表 */
  waitQuitList: LeaveOfficeListTypes[];
  /**待离职分页 */
  waitQuitListPagination: IPagination;
  /**待离职查询入参 */
  waitQuitSearchParams: SearchParamType;
  /**待离职模块参数 */
  waitQuitModuleParams: WaitQuitModuleParamType;
  /**已离职列表 */
  quitList: CancelEntryListTypes[];
  /**已离职分页 */
  quitListPagination: IPagination;
  /**已离职查询入参 */
  quitSearchParams: SearchParamType;
  /**已离职模块参数 */
  quitModuleParams: ModuleParamType;
}

/**离职管理模块初始化State */
export const initLeaveOfficeState: LeaveOfficeState = {
  leaveOfficeModuleParams: {
    currentTab: "1"
  },
  waitQuitList: [],
  waitQuitListPagination: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  quitList: [],
  quitListPagination: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  waitQuitModuleParams: {
    isExpanded: false,
    curCard: "0"
  },
  waitQuitSearchParams: {
    isQueryLessToday: 0,
    searchCriteria: "",
    contractCompanyCode: [],
    employeeType: [],
    // entryDate: moment().format("YYYY-MM-DD"),
    startEntryDate: "",
    endEntryDate: "",
    // quitDate: moment().format("YYYY-MM-DD"),
    startQuitDate: "",
    endQuitDate: "",
    groupCodes: [],
    leaderCode: [],
    mobile: "",
    postCode: [],
    rankCode: [],
    userEmail: "",
    userName: "",
    workPlaceCode: [],
    transactionReason: [],
    operateType: [],
    // isRegister: 0,
    pageIndex: VhTools.getInitPagination().current,
    pageSize: VhTools.getInitPagination().pageSize,
  },
  quitSearchParams: {
    searchCriteria: "",
    contractCompanyCode: [],
    employeeType: [],
    // entryDate: moment().format("YYYY-MM-DD"),
    startEntryDate: "",
    endEntryDate: "",
    // quitDate: moment().format("YYYY-MM-DD"),
    startQuitDate: "",
    endQuitDate: "",
    groupCodes: [],
    leaderCode: [],
    mobile: "",
    postCode: [],
    rankCode: [],
    userEmail: "",
    userName: "",
    workPlaceCode: [],
    transactionReason: [],
    operateType: [],
    pageIndex: VhTools.getInitPagination().current,
    pageSize: VhTools.getInitPagination().pageSize,
  },
  quitModuleParams: {
    isExpanded: false
  },
}

const namespace: string = "leaveOffice";

export default modelExtend<LeaveOfficeState>({
  namespace,
  state: initLeaveOfficeState,
  effects: {
    /**查询待离职列表 */
    *queryWaitQuitList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const { fetchMaxData } = payload;
      const res: any = yield call(fetchWaitQuitList, payload);
      if (fetchMaxData) return res;
      yield put({
        type: "updateState",
        payload: {
          waitQuitSearchParams: { ...payload }
        }
      })
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            waitQuitList: res.data.records || [],
            waitQuitListPagination: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },
    /**查询已离职列表 */
    *queryQuitList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          quitSearchParams: { ...payload }
        }
      })
      const res: any = yield call(fetchQuitList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            quitList: res.data.records || [],
            quitListPagination: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },

    /**统计数据 */
    *queryStatistics(_action, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchStatistics);
      return res
    },
    /**添加离职 */
    *doLeaveOfficeAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchLeaveOfficeAdd, payload);
      return res
    },
    /**确认离职 */
    *doLeaveOfficeConfirm({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchLeaveOfficeConfirm, payload);
      return res
    },
    /**撤销离职 */
    *doLeaveOfficeCancel({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchLeaveOfficeCancel, payload);
      return res
    },
    /**恢复离职 */
    *doLeaveOfficeRecovery({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchLeaveOfficeRecovery, payload);
      return res
    },
    /**调整离职信息 */
    *doLeaveOfficeAdjust({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchLeaveOfficeAdjust, payload);
      return res
    },
    /**重新入职 */
    *doLeaveOfficeReEntry({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchLeaveOfficeReEntry, payload);
      return res
    },
    /**校验重新入职 */
    *doLeaveOfficeValidateReEntry({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchLeaveOfficeValidateReEntry, payload);
      return res
    },
    /**待离职列表导出 */
    *doWaitQuitExport({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchWaitQuitExport, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
    /**已离职列表导出 */
    *doQuitExport({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchQuitExport, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
    /**更新leaveOfficeModuleParam */
    *doLeaveOfficeModuleParamUpdate({ payload }: ReduxAction, { put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          leaveOfficeModuleParams: { ...payload }
        }
      })
    },
    /**更新waitQuitSearchParam */
    *doWaitQuitSearchParamUpdate({ payload }: ReduxAction, { put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          waitQuitSearchParams: { ...payload }
        }
      })
    },
    /**更新quitSearchParams */
    *doQuitSearchParamsUpdate({ payload }: ReduxAction, { put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          quitSearchParams: { ...payload }
        }
      })
    },
    /**更新waitQuitModuleParam */
    *doWaitQuitModuleParamUpdate({ payload }: ReduxAction, { put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          waitQuitModuleParams: { ...payload }
        }
      })
    },
    /**更新quitModuleParam */
    *doQuitModuleParamUpdate({ payload }: ReduxAction, { put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          quitModuleParams: { ...payload }
        }
      })
    },
  }
})




