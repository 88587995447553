import { Tooltip } from "antd";
import React from "react";

/**返回HTML对象 */
export const getNodeByString = (content: string) => {
  if (content.indexOf("\n") > -1) {
    return (
      <div style={{ paddingLeft: 38 }}>
        {content.split("\n").map(item => <div key={item}>{item}</div>)}
      </div>
    )
  }
  return <div style={{ paddingLeft: 38 }} dangerouslySetInnerHTML={{ __html: content }} />
}

/** 悬浮框显示 */
export const onSetToolTip = (text: string, limit: number = 10, direction: any = "top") => (
  text ? (
    <div className="tip-remark">
      <Tooltip title={text} placement={direction}>
        {text.length > limit ? `${text.substr(0, limit - 1)}...` : text}
      </Tooltip>
    </div>
  ) : "--"
)

/** 选择框过滤 */
export const onfilterOption = (input: any, option: any) => {
  return option.props.children?.indexOf(input.toLowerCase().trim()) >= 0;
}