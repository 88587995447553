import fetchApi from "utils/fetchApi";

/**查询转正人员统计数据 */
const fetchEmployeePassStatictics = () => fetchApi("get", "api/employee/regular/statistics")

/**查询转正人员列表数据 */
const fetchEmployeePassList = (data: any) => fetchApi("post", "api/employee/regular/pageList", data)

/**确认转正 */
const fetchConfirmPass = (data: any) => fetchApi("post", "api/employee/regular/confirmRegular", data)

/**调整转正日期 */
const fetchUpdatePassDate = (data: any) => fetchApi("post", "api/employee/regular/updateRegularDate", data)

/**撤销转正 */
const fetchCancelPass = (employeeCode: string) => fetchApi("get", `api/employee/regular/revokeRegular/${employeeCode}`)

/** 导出 */
const fetchExportList = (data: any) => fetchApi("post", "api/common/exportEmployeeRegular", data)

export {
  fetchEmployeePassStatictics,
  fetchEmployeePassList,
  fetchConfirmPass,
  fetchUpdatePassDate,
  fetchCancelPass,
  fetchExportList
}