import React, { useState, useRef } from "react";
import { Button, Checkbox, Form, Input, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import MsCompanyPickModal from "./MsCompanyPickModal";
import { ApplicationState } from "models";
import { connect } from "dva";
import { ICompany } from "common/types";
import Tools from "utils/tools";
import images from "utils/images";
import fetchApi from "utils/fetchApi";
import "./MsLogin.scss";

interface PropsFromState {
  dispatch: any;
  loading: boolean;
}

interface ImageCodeType {
  /**显示图片验证码 */
  visible: boolean;
  /**图片验证码code */
  imageCodeKey: string;
  /**图片验证码url */
  imageCodeUrl: string;
}

type AllProps = PropsFromState & FormComponentProps;

const mapStateToProps = ({ loading }: ApplicationState) => ({
  loading: loading.effects["app/login"]
})

/**
 * 登录页面
 */
const MsLogin = (props: AllProps) => {

  const { dispatch, loading, form: { getFieldDecorator, validateFields } } = props

  /** 有效期 */
  const expireDateRef = useRef<Date>(new Date());
  /** 公司列表 */
  const [companyList, setCompanyList] = useState<ICompany[]>([])
  /** 公司弹框 */
  const [showPickModal, setShowPickModal] = useState<boolean>(false)
  /** 错误提示 */
  const [errorText, setErrorText] = useState<string>("")
  /** 图形验证码 */
  const [imageCode, setImageCode] = useState<ImageCodeType>({
    visible: false,
    imageCodeUrl: "",
    imageCodeKey: ""
  })

  /** 获取图形验证码 */
  const onGetImageCode = async () => {
    const imageCodeKey = Math.floor(Math.random() * 1000000) + 100000;
    setImageCode({
      ...imageCode,
      visible: true,
      imageCodeKey: imageCodeKey.toString(),
      imageCodeUrl: `/api/login/imageCode?imageCodeKey=${imageCodeKey}&inCase=2`
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const params = {
          ...values,
          rememberMe: values.rememberMe ? 1 : 0,
          hideError: true
        }
        dispatch({ type: "app/login", payload: params }).then((res: any) => {
          if (res?.result === 0 && res.data?.userId) {
            const { seconds, token, companyList } = res.data
            setErrorText("")
            /** 缓存token */
            const date = new Date();
            date.setDate(date.getDate() + seconds / 3600 / 24)
            /** 保存过期时间 */
            expireDateRef.current = date
            Tools.setCookie("ms_member_token", token, date, "smarthr.cn")
            Tools.setCookie("ms_expire", date.toString(), date, "smarthr.cn")
            if (companyList?.length > 1) {
              setCompanyList(res.data.companyList)
              setShowPickModal(true)
            }
            else if (companyList?.length === 1) {
              const [{ companyId, roleType }] = companyList
              if (roleType === 1) {
                Tools.setCookie("ms_cid", companyId, date, "smarthr.cn")
                window.location.href = "/shr/organization/structure"
              } else {
                Tools.delCookie("ms_member_token");
                Tools.delCookie("ms_cid");
                Tools.delCookie("ms_expire");
                Tools.delCookie("ms_appCode");
                Modal.error({
                  centered: true,
                  content: "无访问权限"
                })
              }
            }
          } else {
            setErrorText(res?.detail || "登录失败")
            onGetImageCode()
          }
        })
      }
    })
  }

  // 选择登录公司
  const handleSelectCompany = (companyId: string, roleType: number) => {
    if (roleType === 1) {
      Tools.setCookie("ms_cid", companyId, expireDateRef.current, "smarthr.cn")
      setShowPickModal(false)
      window.location.href = "/shr/organization/structure"
    } else {
      Tools.delCookie("ms_member_token");
      Tools.delCookie("ms_cid");
      Tools.delCookie("ms_appCode");
      Modal.error({
        centered: true,
        content: "无访问权限"
      })
    }
  }

  // 校验字段
  const onCheckFields = (name: string) => {
    return async (_: any, value: any, callback: any) => {
      value = value ? value.trim() : "";
      if (!value) {
        callback(`请输入${name}`);
      } else {
        if (name === "图形验证码" && imageCode.visible && imageCode.imageCodeKey) {
          const res: any = await fetchApi("get", `api/login/checkImageCode?imageCodeKey=${imageCode.imageCodeKey}&verifyCode=${value}&case=2`, { hideError: true });
          if (res?.result === 0) {
            callback();
          } else {
            callback(res?.detail || "图片验证码错误");
          }
        }
      }
    }
  }

  return (
    <div className="ms-login">
      <div className="login-content">
        <div className="login-topic">
          <div>HR 共享服务平台</div>
          <div>简单、高效、智能、人性化的人力资源管理系统</div>
        </div>
        <div className="login-box">
          <img className="logo" src={images.logo} />
          <Form className="form" onSubmit={handleSubmit} colon={false}>
            <Form.Item label="账号">
              {getFieldDecorator("userName", {
                initialValue: "",
                rules: [{ validator: onCheckFields("账号") }],
              })(
                <Input placeholder="输入您的邮箱/手机号" maxLength={50} />
              )}
            </Form.Item>
            <Form.Item label="密码">
              {getFieldDecorator("password", {
                initialValue: "",
                rules: [{ validator: onCheckFields("密码") }],
              })(
                <Input type="password" placeholder="输入您的密码" />
              )}
            </Form.Item>
            {imageCode.visible && (
              <Form.Item label="图形验证码">
                {getFieldDecorator("verifyCode", {
                  initialValue: "",
                  rules: [{ validator: onCheckFields("图形验证码") }],
                  validateTrigger: ["onBlur"],
                })(
                  <Input placeholder="输入图形验证码" maxLength={6} suffix={(
                    <img src={imageCode.imageCodeUrl} style={{ cursor: "pointer", marginBottom: 5 }} alt="图形验证码" onClick={onGetImageCode} />
                  )} />
                )}
              </Form.Item>
            )}
            <Form.Item>
              {getFieldDecorator("rememberMe", {
                valuePropName: "checked",
                initialValue: true
              })(
                <Checkbox>记住密码</Checkbox>
              )}
              {errorText && (
                <div className="ant-form-explain error">{errorText}</div>
              )}
              <Button htmlType="submit" type="primary" block={true} className="login-btn" loading={loading}>登录</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="copy-right">2009—{new Date().getFullYear()} © 上海微知数字科技有限公司版权所有@沪ICP备09067369号-6</div>
      {showPickModal && (
        <MsCompanyPickModal
          dataSource={companyList}
          onOk={handleSelectCompany}
          onClose={() => setShowPickModal(false)}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(Form.create<FormComponentProps>()(MsLogin))