import fetchApi from "utils/fetchApi";

/**查询待离职列表 */
const fetchWaitQuitList = (data: any) => fetchApi("post", "api/employee/quit/waitingQuit/pageList", data)

/**查询已离职列表 */
const fetchQuitList = (data: any) => fetchApi("post", "api/employee/quit/quit/pageList", data)

/**添加离职 */
const fetchLeaveOfficeAdd = (data: any) => fetchApi("post", "api/postManager/save", data)

/**确认离职 */
const fetchLeaveOfficeConfirm = (data: any) => fetchApi("post", "api/employee/quit/confirmQuit", data)

/**撤销离职 */
const fetchLeaveOfficeCancel = (data: any) => fetchApi("get", `api/employee/quit/cancel/quit?employeeInfoId=${data.employeeInfoId}&employeeOperateInfoId=${data.employeeOperateInfoId}`)

/** 恢复离职 */
const fetchLeaveOfficeRecovery = (data: any) => fetchApi("post", "api/postManager/recovery", data)

/** 调整离职信息 */
const fetchLeaveOfficeAdjust = (data: any) => fetchApi("post", "api/employee/quit/update/quitInfo", data)

/** 重新入职 */
const fetchLeaveOfficeReEntry = (data: any) => fetchApi("post", "api/employee/quit/againEntry", data)

/** 校验重新入职 */
const fetchLeaveOfficeValidateReEntry = (data: any) => fetchApi("post", "api/employee/quit/validate/againEntry", data)

/** 查询待离职统计数据 */
const fetchStatistics = (data: any) => fetchApi("get", "api/employee/quit/waitingQuit/statistics")

/**待离职导出 */
const fetchWaitQuitExport = (data: any) => fetchApi("post", "api/employee/quit/waitingQuit/export", data)

/**已离职导出 */
const fetchQuitExport = (data: any) => fetchApi("post", "api/employee/quit/quit/export", data)

export {
  fetchWaitQuitList,
  fetchLeaveOfficeAdd,
  fetchLeaveOfficeConfirm,
  fetchLeaveOfficeCancel,
  fetchLeaveOfficeRecovery,
  fetchLeaveOfficeAdjust,
  fetchStatistics,
  fetchQuitList,
  fetchLeaveOfficeReEntry,
  fetchLeaveOfficeValidateReEntry,
  fetchWaitQuitExport,
  fetchQuitExport
}