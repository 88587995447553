import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { VhTools } from "msd";
import { fetchSuperAdminList, fetchAddSuperAdmin, fetchDeleteSuperAdmin, fetchQueryUser } from "services/admin";
import { modelExtend } from "./common";


// 超级管理员列表类型
export interface SuperAdminTypes {
  /** 是否超管, 1 是 0 否 */
  isSuperAdmin: number;
  /** 更新人姓名 */
  modifyName: string
  /** 更新时间 */
  modifyTime: string
  /** 权限系统tRole表ID */
  roleId: string
  /** 会员域id */
  uid: string
  /** 用户id */
  userId: string
  /** 用户姓名 */
  userName: string
  /** 管理范围 */
  managerScope: string
  /** 权限 */
  permission: string
  /** 更新人id */
  modifyId: string
}

export interface UserTypes {
  employeeCode: string,
  employeeName: string,
  uid: string,
}


/**超级管理员模块State对象 */
export interface SuperAdminState {
  /** 列表数据 */
  superAdminList: SuperAdminTypes[];
  /** 列表分页数据 */
  pageData: IPagination;
  /** 员工列表数据 */
  userList: UserTypes[]
}

/**超级管理员模块初始化State */
export const initSuperAdminState: SuperAdminState = {
  superAdminList: [],
  pageData: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  userList: []
}

const namespace: string = "admin";

export default modelExtend<SuperAdminState>({
  namespace,
  state: initSuperAdminState,
  effects: {
    /** 查询超级管理员列表数据 */
    *querySuperAdminList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchSuperAdminList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            superAdminList: res.data.records || [],
            pageData: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },
    *queryUserList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchQueryUser, payload)
      yield put({
        type: "updateState",
        payload: {
          userList: res.data || []
        }
      })
    },
    /** 添加超级管理员 */
    *addSuperAdmin({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchAddSuperAdmin, payload)
      return res
    },
    /** 移除超级管理员 */
    *deleteSuperAdmin({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchDeleteSuperAdmin, payload)
      return res
    },
  }
})
