import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import App from "./App";
import { createBrowserHistory, createHashHistory } from "history";
import Tools from "utils/tools";
import dva from "dva";
import createLoading from "dva-loading";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

const history = Tools.supportsHistory() ? createBrowserHistory() : createHashHistory();

const app = dva({
  history,
  ...createLoading({
    effects: true,
  })
})


require("./models").default.forEach((key: any) => app.model(key.default))

app.router(() => <App history={history} />)

app.start("#root")

serviceWorker.unregister();