import { Icon } from "antd";
import { VhTools } from "msd";
import "antd/lib/icon/style";

let VhIconCommon = Icon.createFromIconfontCN({ scriptUrl: "" })

// 获取icn图标地址
const fetchIcon = async () => {
  const TOKEN = VhTools.getCookie("ms_member_token") || "";
  const CID = VhTools.getCookie("ms_cid") || "";
  
  // 查询字体图标地址
  fetch(`${window.location.protocol}//${window.location.host}/home-server/common/queryIconAddress`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + TOKEN,
      "Mscid": CID,
      "terminal": "752"
    },
  })
  .then(response => response.json())
  .then(res => {
    VhIconCommon = Icon.createFromIconfontCN({ scriptUrl: res.data })
  })
  .catch(e => e);
}

fetchIcon()

export default VhIconCommon;
