import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { VhTools } from "msd";
import {
  fetchContractCompanyList,
  fetchContractCompanyAdd,
  fetchContractCompanyUpdate,
  fetchContractCompanyDelete,
  fetchContractCompanyExport,
  fetchContractCompanyValidate
} from "services/contractCompany";
import { modelExtend } from "./common";


// 合同公司列表类型
export interface ContractCompanyListTypes {
  [x: string]: any;
  /**公司名称 */
  title: string;
  /**公司code */
  contractCompanyCode: string;
  /**id */
  id: number;
}



/**合同公司模块State对象 */
export interface ContractCompanyState {
  contractCompanyList: ContractCompanyListTypes[];
  contractCompanyListPagination: IPagination;
}

const initListPagination: IPagination = {
  pageSize: VhTools.getInitPagination().pageSize,
  current: VhTools.getInitPagination().current,
  total: 0
}

/**合同公司模块初始化State */
export const initContractCompanyState: ContractCompanyState = {
  contractCompanyList: [],
  contractCompanyListPagination: initListPagination
}

const namespace: string = "contractCompany";

export default modelExtend<ContractCompanyState>({
  namespace,
  state: initContractCompanyState,
  effects: {
    /**查询合同公司列表 */
    *queryContractCompanyList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const { fetchMaxData } = payload;
      const res: any = yield call(fetchContractCompanyList, payload);
      if (fetchMaxData) { return res }
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            contractCompanyList: res.data.records || [],
            contractCompanyListPagination: res.data.pageIndex ? {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            } : initListPagination
          }
        })
      }
    },
    /**添加合同公司 */
    *doContractCompanyAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractCompanyAdd, payload);
      return res
    },
    /**更新合同公司 */
    *doContractCompanyUpdate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractCompanyUpdate, payload);
      return res
    },
    /**校验合同公司 */
    *doContractCompanyValidate({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractCompanyValidate, payload);
      return res
    },
    /**删除合同公司 */
    *doContractCompanyDelete({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractCompanyDelete, payload);
      return res
    },
    /**合同公司列表导出 */
    *doContractCompanyExport({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchContractCompanyExport, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
  }
})
