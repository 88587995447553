import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { VhTools } from "msd";
import {
  fetchOnBoardList,
  fetchOnBoardAdd,
  fetchOnBoardRecovery,
  fetchOnBoardCancel,
  fetchOnBoardConfirm,
  fetchOnBoardAdjust,
  fetchStatistics,
  fetchCancelEntryList,
  fetchOnBoardSend,
  fetchOnBoardExport,
  fetchCancelEntryExport,
  fetchOnBoardValidateAdd,
  fetchOnBoardValidateConfirm,
  fetchOnBoardValidateRecovery,
  fetchTemplateUrl,
  fetchExcelImport
} from "services/onBoard";
import { modelExtend } from "./common";


/**待入职列表类型 */
export interface OnBoardListTypes {
  [x: string]: any;
  /**待入职名称 */
  postName: string;
  /**主键 */
  employeeInfoId: number;
  transactionReason: string;
}

/**已取消入职列表类型 */
export interface CancelEntryListTypes {
  [x: string]: any;
  /**取消入职原因 */
  transactionReason: string;
}

/**待入职查询入参 */
export interface SearchParamType {
  [x: string]: any;
  /**模糊查询姓名或手机号 */
  searchCriteria: string;
}

/**已取消入职查询入参 */
export interface CancelEntrySearchParamType {
  [x: string]: any;
  /**模糊查询姓名或手机号 */
  searchCriteria: string;
}

/**模块私有参数 */
export interface ModuleParamType {
  /**是否展开 */
  isExpanded: boolean;
  /**当前所处的tab */
  currentTab: string;
  /**当前card */
  curCard: string;
}


/**入职管理模块State对象 */
export interface OnBoardState {
  /**待入职列表 */
  onBoardList: OnBoardListTypes[];
  /**待入职分页 */
  onBoardListPagination: IPagination;
  /**待入职查询入参 */
  searchParams: SearchParamType;
  /**已取消入职列表 */
  cancelEntryList: CancelEntryListTypes[];
  /**已取消入职分页 */
  cancelEntryListPagination: IPagination;
  /**已取消入职查询入参 */
  cancelEntrySearchParams: CancelEntrySearchParamType;
  /**待入职模块参数 */
  moduleParams: ModuleParamType;
}

/**入职管理模块初始化State */
export const initOnBoardState: OnBoardState = {
  onBoardList: [],
  onBoardListPagination: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },
  cancelEntryList: [],
  cancelEntryListPagination: {
    pageSize: VhTools.getInitPagination().pageSize,
    current: VhTools.getInitPagination().current,
    total: 0
  },

  moduleParams: {
    isExpanded: false,
    currentTab: "1",
    curCard: "0"
  },
  searchParams: {
    searchCriteria: "",
    contractCompanyCode: [],
    employeeType: [],
    startEnrollmentDate: "",
    // startEnrollmentDate: "2022-01-01",
    endEnrollmentDate: "",
    // endEnrollmentDate: "2023-10-31",
    groupCodes: [],
    leaderCode: [],
    mobile: "",
    postCode: [],
    rankCode: [],
    userEmail: "",
    userName: "",
    workPlaceCode: [],
    isRegister: "",
    pageIndex: VhTools.getInitPagination().current,
    pageSize: VhTools.getInitPagination().pageSize,
  },
  cancelEntrySearchParams: {
    searchCriteria: "",
    pageIndex: VhTools.getInitPagination().current,
    pageSize: VhTools.getInitPagination().pageSize,
  },
}

const namespace: string = "onBoard";

export default modelExtend<OnBoardState>({
  namespace,
  state: initOnBoardState,
  effects: {
    /**查询待入职列表 */
    *queryOnBoardList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          searchParams: { ...payload }
        }
      })
      const res: any = yield call(fetchOnBoardList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            onBoardList: res.data.records || [],
            onBoardListPagination: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },
    /**查询已取消入职列表 */
    *queryCancelEntryList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          cancelEntrySearchParams: { ...payload }
        }
      })
      const res: any = yield call(fetchCancelEntryList, payload);
      if (res?.result === 0) {
        yield put({
          type: "updateState",
          payload: {
            cancelEntryList: res.data.records || [],
            cancelEntryListPagination: {
              pageSize: res.data.pageSize,
              current: res.data.pageIndex,
              total: res.data.totalCount
            }
          }
        })
      }
    },
    /**统计数据 */
    *queryStatistics(_action: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchStatistics);
      return res
    },
    /**添加入职 */
    *doOnBoardAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardAdd, payload);
      return res
    },
    /**校验添加入职 */
    *doOnBoardValidateAdd({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardValidateAdd, payload);
      return res
    },
    /**确认入职 */
    *doOnBoardConfirm({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardConfirm, payload);
      return res
    },
    /**校验确认入职 */
    *doOnBoardValidateConfirm({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardValidateConfirm, payload);
      return res
    },
    /**取消入职 */
    *doOnBoardCancel({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardCancel, payload);
      return res
    },
    /**恢复入职 */
    *doOnBoardRecovery({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardRecovery, payload);
      return res
    },
    /**校验恢复入职 */
    *doOnBoardValidateRecovery({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardValidateRecovery, payload);
      return res
    },
    /**调整日期 */
    *doOnBoardAdjust({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardAdjust, payload);
      return res
    },
    /**发送入职登记表 */
    *doOnBoardSend({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardSend, payload);
      return res
    },
    /**查询导入模板地址 */
    *queryImportTemplateUrl(_action: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchTemplateUrl);
      return res
    },
    /**批量导入 */
    *doExcelImport({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
      const res: any = yield call(fetchExcelImport, payload);
      return res
    },
    /**onBoard列表导出 */
    *doOnBoardExport({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchOnBoardExport, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
    /**cancelEntry列表导出 */
    *doCancelEntryExport({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchCancelEntryExport, payload);
      if (res?.result === 0) {
        yield put({
          type: "app/queryExportRate",
          payload: {
            taskId: res.data.extra.taskId,
            stopDownload: false
          },
          callback
        })
      }
    },
    /**更新searchParam */
    *doOnBoardSearchParamUpdate({ payload }: ReduxAction, { put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          searchParams: { ...payload }
        }
      })
    },
    /**更新cancelEntrySearchParams */
    *doCancelEntrySearchParamsUpdate({ payload }: ReduxAction, { put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          cancelEntrySearchParams: { ...payload }
        }
      })
    },
    /**更新moduleParam */
    *doOnBoardModuleParamUpdate({ payload }: ReduxAction, { put }: ReduxSagaEffects): any {
      yield put({
        type: "updateState",
        payload: {
          moduleParams: { ...payload }
        }
      })
    },
  }
})




