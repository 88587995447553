import React from "react";
import { Switch, Route, Redirect, Router } from "dva/router";
import { ConfigProvider, Empty, Modal } from "antd";
import { CID, TOKEN, env, EXPIRE_MESSAGE, APP_CODE, MOSS_LOGIN_URL, LOGIN_URL } from "common/constants";
import zhCN from "antd/lib/locale-provider/zh_CN";
import "moment/locale/zh-cn";
import { Updater } from "utils/updater"
import { History } from "history";
import MsLayout from "components/ms-layout/MsLayout";
import MsLazy from "components/ms-lazy/MsLazy";
import MsLogin from "pages/ms-login/MsLogin";

interface AppProps {
  history: History;
}

const MsPreviewRegisterForm = MsLazy(() => import("pages/ms-setting/ms-preview-register-form/MsPreviewRegisterForm"))

class App extends React.Component<AppProps> {

  componentDidMount() {
    const isLocal = location.href?.indexOf("localhost") > -1
    if (env !== "pro" && !isLocal) {
      const up = new Updater({ timer: 300000 })
      up.on("update", () => {
        Modal.destroyAll()
        Modal.confirm({
          title: "提示",
          content: "网页功能已更新，体验新功能，需要刷新页面，是否刷新页面？",
          onOk: () => {
            window.location.reload()
          }
        })
      })
    }
  }

  render() {
    if ((!TOKEN || !CID) && location.href.indexOf(LOGIN_URL) === -1) {
      Modal.error({
        centered: true,
        content: EXPIRE_MESSAGE,
        onOk: () => {
          this.props.history.replace( location.href.indexOf("smarthr.viphrm.com") > -1 ? MOSS_LOGIN_URL : LOGIN_URL)
        }
      })
    }
    return (
      <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false} renderEmpty={() => <Empty />}>
        <Router history={this.props.history}>
          <Switch>
            <Route path="/shr" component={MsLayout} />
            <Route path="/login" exact={true} component={MsLogin} />
            <Route path="/MsPreviewRegisterForm" exact={false} component={MsPreviewRegisterForm} />
            <Redirect to="/shr" />
          </Switch>
        </Router>
      </ConfigProvider>
    )
  }
}

export default App;
