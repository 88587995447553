import fetchApi from "utils/fetchApi";

/**验证服务费 */
const fetchHasServiceFee = (data: any) => fetchApi("post", "ehr/web/validateIsHaveServiceFee", data)

/**查询成本中心列表 */
const fetchCostCenterList = (data: any) => fetchApi("post", "ehr/web/queryCostCenterRoleNew1", data)

/**查询参保城市列表 */
const fetchInsureCityList = (data: any) => fetchApi("post", "ehr/web/getProvinceAndCityByComopany", data)

/**查询参保账户 */
const fetchAccountList = (data: any) => fetchApi("post", "ehr/web/getAccount", data)

/**查询参保账套列表 */
const fetchGroupList = (data: any) => fetchApi("post", "ehr/web/getGroupName", data)

/**查询起缴月份范围值、补缴月份范围值、基数范围接口 */
const fetchRadix = (data: any) => fetchApi("post", "ehr/web/getRadix", data)

/**查询城市当期缴纳节点 */
const fetchStartDate = (data: any) => fetchApi("post", "ehr/web/getStartDate", data)

export {
    fetchHasServiceFee,
    fetchCostCenterList,
    fetchInsureCityList,
    fetchAccountList,
    fetchGroupList,
    fetchRadix,
    fetchStartDate
}
