import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IPagination } from "common/types";
import { StatisticsDataType } from "components/ms-statistics/MsStatistics";
import { VhTools } from "msd";
import {
    fetchSIProgressList,
    fetchSIProgressStatictics,
    fetchCancelApply,
    fetchExportSIProgress,
    fetchSearchCityList
} from "services/sipProgress";
import { modelExtend } from "./common";


// 参保进度列表类型
export interface SIProgressListTypes {
    [x: string]: any;
}

// 参保进度列表参数类型
export interface SIProgressSearchParamsType {
    [x: string]: any;
    /**模糊查询姓名或手机号 */
    mixParam: string;
}

/**撤销申请 入参*/
export interface SIPCancelApplyType {
    cancelPF?: number;
    cancelSS?: number;
    employeeCode?: string;
}

/**参保城市类型 */
export interface SIPProgressCityListType {
    cityId: number;
    cityText: string;
    provinceId: number;
    provinceText: string;
}

/**参保进度State对象 */
export interface SIProgressState {
    /** 统计数据 */
    statisticsData: StatisticsDataType[][];
    /** 列表数据 */
    sipProgressList: SIProgressListTypes[];
    /**参保城市列表 */
    sipProgressCityList: SIPProgressCityListType[];
    /** 列表分页数据 */
    pageData: IPagination;
    /**选中卡片 */
    cardIndex: number;
    /**展开、收起 */
    isExpanded: boolean;
    /**查询参数 */
    sipProgressSearchParams: SIProgressSearchParamsType;
}

/**参保员工初始化State */
export const initSIProgressState: SIProgressState = {
    statisticsData: [
        [
            { label: "社保中增员", value: 0, searchType: "ssIncrease" },
            { label: "公积金增员中", value: 0, searchType: "pfIncrease" },
            { label: "社保减员中", value: 0, searchType: "ssReduce" },
            { label: "公积金减员中", value: 0, searchType: "pfReduce" },
        ],
        [
            { label: "异常任务", value: 4, searchType: "exception" },
        ]
    ],
    sipProgressList: [],
    pageData: {
        pageSize: VhTools.getInitPagination().pageSize,
        current: VhTools.getInitPagination().current,
        total: 0
    },
    sipProgressCityList: [],
    cardIndex: 0,
    isExpanded: false,
    sipProgressSearchParams: {
        mixParam: "",
        cityIdT: [],
        serviceTypeT: [],
        increaseTypeT: [],
        statusT: [],
        createTimeBegin: "",
        createTimeEnd: "",
        accountOwnerTypeT: [],
        accountIdT: [],
        employeeTypeT: [],
        groupCodeT: [],
        pageIndex: VhTools.getInitPagination().current,
        pageSize: VhTools.getInitPagination().pageSize,
    }
}

const namespace: string = "sipProgress";

export default modelExtend<SIProgressState>({
    namespace,
    state: initSIProgressState,
    effects: {
        /** 查询统计数据 */
        *queryStatisticsData(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const res: any = yield call(fetchSIProgressStatictics)
            if (res?.result === 0) {
                const data = [
                    [
                        { label: "全部", value: res.data?.total || 0, searchType: "total" },
                        { label: "社保参保中", value: res.data?.ssIncrease || 0, searchType: "ssIncrease" },
                        { label: "公积金参保中", value: res.data?.pfIncrease || 0, searchType: "pfIncrease" },
                        { label: "社保停保中", value: res.data?.ssReduce || 0, searchType: "ssReduce" },
                        { label: "公积金停保中", value: res.data?.pfReduce || 0, searchType: "pfReduce" },
                    ],
                    [
                        { label: "异常任务", value: res.data?.exception || 0, searchType: "exception" },
                    ]
                ]
                yield put({
                    type: "updateState",
                    payload: {
                        statisticsData: data
                    }
                })
            }
        },
        /**查询列表数据 */
        *querySIProgressList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const res: any = yield call(fetchSIProgressList, payload);
            if (res?.result === 0) {
                yield put({
                    type: "updateState",
                    payload: {
                        sipProgressSearchParams: { ...payload },
                        sipProgressList: res.data.records || [],
                        pageData: {
                            pageSize: res.data.pageSize,
                            current: res.data.pageIndex,
                            total: res.data.totalCount
                        }
                    }
                })
            }
        },
        /**查询参保城市列表 */
        *querySIProgressCityList(_action: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const res: any = yield call(fetchSearchCityList);
            if (res?.result === 0) {
                yield put({
                    type: "updateState",
                    payload: {
                        sipProgressCityList: res.data || []
                    }
                })
            }
        },
        /** 撤销增减员 */
        *cancelApply({ payload }: ReduxAction, { call }: ReduxSagaEffects): any {
            const res: any = yield call(fetchCancelApply, payload)
            return res
        },
        /** 列表数据导出 */
        *exportList({ payload, callback }: ReduxAction, { call, put }: ReduxSagaEffects): any {
            const res: any = yield call(fetchExportSIProgress, payload);
            if (res?.result === 0) {
                yield put({
                    type: "app/queryExportRate",
                    payload: {
                        taskId: res.data.extra.taskId,
                        stopDownload: false
                    },
                    callback
                })
            }
        },
    }
})
