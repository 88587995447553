import fetchApi from "utils/fetchApi";
import Tools from "utils/tools";

/**查询参保进度统计数据 */
const fetchSIProgressStatictics = () => fetchApi("get", "api/insurance/progress/statistics")

/**查询参保进度列表数据 */
const fetchSIProgressList = (data: any) => fetchApi("get", Tools.appendParams("api/insurance/progress/queryList", data))

/** 撤销增减员 */
const fetchCancelApply = (data: any) => fetchApi("post", "api/insurance/personal/cancel", data)

/** 参保进度数据导出 */
const fetchExportSIProgress = (data: any) => fetchApi("post", Tools.appendParams("api/insurance/progress/export", data))

/**查询参保城市列表（搜索栏） */
const fetchSearchCityList = () => fetchApi("get", "api/insurance/progress/selectCitys")

export {
  fetchSIProgressStatictics,
  fetchSIProgressList,
  fetchCancelApply,
  fetchExportSIProgress,
  fetchSearchCityList
}
