import { ReduxAction, ReduxSagaEffects } from "common/dvatypes";
import { IMapType } from "common/types";
import { fetchAccountList, fetchCostCenterList, fetchInsureCityList, fetchGroupList } from "services/socialInsurance";
import { modelExtend } from "./common";

// 成本中心列表类型
export interface CostCenterListType {
  /**公司id */
  companyId: number;
  createTime: number;
  /**默认成本中心id */
  defCostCenterId: number;
  email: string;
  hrId: number;
  hrName: string;
  /**主键ID */
  id: number;
  /**设置只减不增，为ture时，无法选择 */
  isIncreaseMark: boolean;
  /**成本中心名称 */
  name: string;
  nowDate: number;
  status: number
  tel: string;
}

// 参保城市列表类型
export interface InsureCityListType {
  /**省份ID */
  province: number;
  /**省份名称 */
  provinceText: string;
  /**城市ID */
  city: number;
  /**城市名称 */
  cityText: string;
}

// 参保账户列表类型
export interface AccountListType {
  /**账户ID */
  accountId: number;
  /**账户名称（显示用） */
  accountNum: string;
  /**账户真实名称 */
  accountName: string;
  /**账户类型 1：客户账户（自缴纳），2：供应商账户（大户代理） */
  accountOwnerType: number;
  /**供应商ID */
  supplierId: number;
  /**供应商名称 */
  supplierName: string;
}

// 参保账套列表类型
export interface GroupListType {
  /**账套id */
  groupId: string;
  /**账套名称 */
  groupName: string;
  /**账套类型 1. 社保 2. 公积金 */
  groupType: number;
  /**是否默认 1.是 0.否 */
  isDefault: number;
}

// 参保方案基本信息类型
export interface InsuranceBasicType {
  /**用户编码 */
  employeeCode: string;
  /**用户名称 */
  employeeName: string;
  /**证件类型 */
  cardType: string;
  /**身份证号 */
  cardCode: string;
  /**手机号 */
  phone: string;
  /**成本中心id */
  costCenterId: string;
  /**省份id */
  provinceId: string;
  /**省份名称 */
  provinceName: string;
  /**城市id */
  cityId: string;
  /**城市名称 */
  cityName: string;
  /**账户Id */
  accountId: string;
  /**账户名称 */
  accountNum: string;
  /**账户真实名称 */
  accountName: string;
  /**账户类型 */
  accountOwnerType: string;
  /**供应商Id */
  supplierId: string;
  /**供应商名称 */
  supplierName: string;
  /**缴纳服务 */
  services: number[];
}

/**参保方案-基数范围类型 */
export interface BaseRangeType {
  /**最小基数 */
  min: number;
  /**最大基数 */
  max: number;
  /**空白文案 */
  emptyText: string;
}

/**参保方案-社保类型 */
export interface SocialInsuranceType {
  /**参保状态 */
  statusName: string;
  /**参保方式 1.新增 2.转入 */
  increaseMode: number;
  /**套餐Id */
  groupId: string;
  /**套餐名称 */
  groupName: string;
  /**起缴月列表 */
  startMonthList: IMapType[];
  /**起缴月 */
  startMonth: string;
  /**起缴月为空 */
  startMonthEmptyText: string;
  /**计算日期 */
  calcDate: string;
  /**补缴月日期 */
  operateDate: string;
  /**补缴月份数 */
  operateNum: string;
  /**补缴月列表 */
  operateMonthList: IMapType[];
  /**补缴月为空 */
  operateMonthEmptyText: string;
  /**基数范围 */
  baseRange: BaseRangeType;
  /**基数 */
  base: string;
  /**社保账号 */
  code: string;
}

/**参保方案-公积金类型 */
export interface PublicFundInsuranceType {
  /**参保状态 */
  statusName: string;
  /**参保方式 1.新增 2.转入 */
  increaseMode: number;
  /**套餐Id */
  groupId: string;
  /**套餐名称 */
  groupName: string;
  /**起缴月范围 */
  startMonthList: IMapType[];
  /**起缴月 */
  startMonth: string;
  /**起缴月为空 */
  startMonthEmptyText: string;
  /**计算日期 */
  calcDate: string;
  /**补缴月日期 */
  operateDate: string;
  /**补缴月份数 */
  operateNum: string;
  /**补缴月列表 */
  operateMonthList: IMapType[];
  /**补缴月为空 */
  operateMonthEmptyText: string;
  /**基数范围 */
  baseRange: BaseRangeType;
  /**公积金基数列表 */
  fundList: any[];
  /**公积金账号 */
  account: string;
  /**基数列表 */
  list: any;
}


// 参保方案数据类型
export interface InsuranceSchemeType {
  /**方案处理中 */
  isProcessing: boolean;
  /**基本信息 */
  basic: InsuranceBasicType;
  /**社保方案 */
  ss: SocialInsuranceType;
  /**公积金方案 */
  pf: PublicFundInsuranceType;
}

/**单人参保State对象 */
export interface SocialInsuranceState {
  /**成本中心列表 */
  costCenterList: CostCenterListType[];
  /**参保城市列表 */
  insureCityList: any[];
  /**参保账户列表 */
  accountList: AccountListType[];
  /**参保账套列表 */
  groupList: GroupListType[];
  /**参保方案数据 */
  insuranceScheme: InsuranceSchemeType;
}

/**初始化方案数据 */
export const initInsuranceScheme: InsuranceSchemeType = {
  isProcessing: false,
  basic: {
    employeeCode: "",
    employeeName: "",
    cardType: "",
    cardCode: "",
    phone: "",
    costCenterId: "",
    provinceId: "",
    provinceName: "",
    cityId: "",
    cityName: "",

    accountId: "",
    accountNum: "",
    accountName: "",
    accountOwnerType: "",
    supplierId: "",
    supplierName: "",

    services: [1, 2]
  },
  ss: {
    statusName: "",
    increaseMode: 1,
    groupId: "",
    groupName: "",
    startMonthList: [],
    startMonth: "",
    startMonthEmptyText: "请选择开始缴纳月份",
    calcDate: "",
    operateNum: "",
    operateDate: "",
    operateMonthList: [],
    operateMonthEmptyText: "请选择补缴月数",
    code: "",
    base: "",
    baseRange: {
      min: 0,
      max: 0,
      emptyText: "参保基数"
    }
  },
  pf: {
    statusName: "",
    increaseMode: 1,
    groupId: "",
    groupName: "",
    startMonth: "",
    startMonthEmptyText: "请选择开始缴纳月份",
    startMonthList: [],
    calcDate: "",
    operateNum: "",
    operateDate: "",
    operateMonthList: [],
    operateMonthEmptyText: "请选择补缴月数",
    fundList: [],
    account: "",
    list: null,
    baseRange: {
      min: 0,
      max: 0,
      emptyText: "参保基数"
    }
  }
}

/**参保员工初始化State */
export const initSocialInsuranceState: SocialInsuranceState = {
  costCenterList: [],
  insureCityList: [],
  accountList: [],
  groupList: [],
  insuranceScheme: initInsuranceScheme
}

const namespace: string = "socialInsurance";

export default modelExtend<SocialInsuranceState>({
  namespace,
  state: initSocialInsuranceState,
  effects: {
    /**查询成本中心列表 */
    *queryCostCenterList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchCostCenterList, payload)
      if (res?.result === 0 && res.data) {
        yield put({
          type: "updateState",
          payload: {
            costCenterList: res.data || []
          }
        })
      }
    },
    /**查询参保城市列表 */
    *queryInsureCityList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchInsureCityList, payload)
      if (res?.result === 0 && res.data?.list) {
        const cityList: InsureCityListType[] = res.data.list
        const insureCityList: any = []
        /**参保城市数据处理，合并省份 */
        cityList.forEach((item: InsureCityListType) => {
          const provinceIndex = insureCityList.findIndex((pitem: any) => pitem.value === item.province)
          if (provinceIndex > -1) {
            insureCityList[provinceIndex].children.push({
              value: item.city,
              label: item.cityText,
            })
          } else {
            insureCityList.push({
              value: item.province,
              label: item.provinceText,
              children: [{
                value: item.city,
                label: item.cityText,
              }]
            })
          }
        })
        yield put({
          type: "updateState",
          payload: {
            insureCityList
          }
        })
      }
    },
    /**查询参保账户列表 */
    *queryAccountList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchAccountList, payload)
      if (res?.result === 0 && res.data) {
        const accountList: AccountListType[] = [{
          ...res.data,
          accountNum: res.data.accountOwnerType === 2 ? "微知大户代理" : res.data.accountNum,
          accountName: res.data.accountNum
        }]
        yield put({
          type: "updateState",
          payload: {
            accountList
          }
        })
      }
    },
    /**查询参保账套列表 */
    *queryGroupList({ payload }: ReduxAction, { call, put }: ReduxSagaEffects): any {
      const res: any = yield call(fetchGroupList, payload)
      if (res?.result === 0 && res.data) {
        yield put({
          type: "updateState",
          payload: {
            groupList: res.data.list || []
          }
        })
      }
    },
  }
})
