import fetchApi from "utils/fetchApi";

/**查询岗位列表 */
const fetchPostList = (data: any) => fetchApi("post", "api/postManager/queryList", data)

/**删除岗位 */
const fetchPostDelete = (data: any) => fetchApi("post", "api/postManager/delete", data)

/** 修改岗位 */
const fetchPostUpdate = (data: any) => fetchApi("post", "api/postManager/update", data)

/**添加岗位 */
const fetchPostAdd = (data: any) => fetchApi("post", "api/postManager/save", data)

/**校验岗位是否重复 */
const fetchPostValidate = (data: any) => fetchApi("post", "api/postManager/checkExists", data)


/**查询职级列表 */
const fetchRankList = (data: any) => fetchApi("post", "api/rankManager/queryList", data)

/**删除职级 */
const fetchRankDelete = (data: any) => fetchApi("post", "api/rankManager/delete", data)

/** 修改职级 */
const fetchRankUpdate = (data: any) => fetchApi("post", "api/rankManager/update", data)

/**添加职级 */
const fetchRankAdd = (data: any) => fetchApi("post", "api/rankManager/save", data)

/**校验职级是否重复 */
const fetchRankValidate = (data: any) => fetchApi("post", "api/rankManager/checkExists", data)


/**查询工作地点列表 */
const fetchWorkPlaceList = (data: any) => fetchApi("post", "api/workPlace/queryList", data)

/**删除工作地点 */
const fetchWorkPlaceDelete = (data: any) => fetchApi("post", "api/workPlace/delete", data)

/** 修改工作地点 */
const fetchWorkPlaceUpdate = (data: any) => fetchApi("post", "api/workPlace/update", data)

/**添加工作地点 */
const fetchWorkPlaceAdd = (data: any) => fetchApi("post", "api/workPlace/save", data)

/**校验工作地点是否重复 */
const fetchWorkPlaceValidate = (data: any) => fetchApi("post", "api/workPlace/checkExists", data)


export {
  fetchPostList,
  fetchPostDelete,
  fetchPostUpdate,
  fetchPostAdd,
  fetchPostValidate,
  fetchRankList,
  fetchRankDelete,
  fetchRankUpdate,
  fetchRankAdd,
  fetchRankValidate,
  fetchWorkPlaceList,
  fetchWorkPlaceUpdate,
  fetchWorkPlaceDelete,
  fetchWorkPlaceAdd,
  fetchWorkPlaceValidate
}