import { AppState } from "./app";
import { ContractCompanyState } from "./contractCompany";
import { GroupState } from "./group";
import { OnBoardState } from "./onBoard";
import { OrginazationState } from "./orginazation";
import { EmployeePassState } from "./employeePass";
import { EmployeeTransferState } from "./employeeTransfer"
import { EmployeeState } from "./employee";
import { LeaveOfficeState } from "./leaveOffice";
import { SuperAdminState } from "./admin";
import { SIEmployeesState } from "./siEmployees";
import { SocialInsuranceState } from "./socialInsurance";
import { SIProgressState } from "./sipProgress";
import { ApplicationConfigState } from "./applicationConfig";

interface LoadingType {
    global: boolean;
    models: Record<string, boolean>;
    effects: Record<string, boolean>;
}

export interface ApplicationState {
    /**加载状态 */
    loading: LoadingType;
    app: AppState;
    /**员工管理-花名册 */
    employee: EmployeeState;
    /**组织管理-组织结构 */
    orginazation: OrginazationState;
    /**部门管理 */
    group: GroupState;
    /**合同公司 */
    contractCompany: ContractCompanyState;
    /**员工管理-入职管理 */
    onBoard: OnBoardState;
    /**员工管理-离职管理 */
    leaveOffice: LeaveOfficeState;
    /** 员工管理-转正管理 */
    employeePass: EmployeePassState;
    /** 员工管理-异动记录 */
    employeeTransfer: EmployeeTransferState;
    /** 超级管理员 */
    admin: SuperAdminState;
    /**社保管理-参保员工 */
    siEmployees: SIEmployeesState;
    /**社保管理-参保方案 */
    socialInsurance: SocialInsuranceState;
    /**社保管理-参保进度 */
    sipProgress: SIProgressState;
    /**应用配置 */
    applicationConfig: ApplicationConfigState;
}

const context = require.context("./", false, /\.ts$/);

export default context
    .keys()
    .filter((item: string) => ["./index.ts", "./common.ts"].indexOf(item) < 0)
    .map((key: any) => context(key))