import fetchApi from "utils/fetchApi";

/**查询待入职列表 */
const fetchOnBoardList = (data: any) => fetchApi("post", "api/employee/entry/waitingEntry/pageList", data)

/**查询已取消入职列表 */
const fetchCancelEntryList = (data: any) => fetchApi("post", "api/employee/entry/cancelEntry/pageList", data)

/**添加入职 */
const fetchOnBoardAdd = (data: any) => fetchApi("post", "api/employee/entry/saveWaitingEntry", data)

/**校验添加入职 */
const fetchOnBoardValidateAdd = (data: any) => fetchApi("post", "api/employee/entry/validateSaveWaitingEntry", data)

/**确认入职 */
const fetchOnBoardConfirm = (data: any) => fetchApi("post", "api/employee/entry/confirmEntry", data)

/**校验确认入职 */
const fetchOnBoardValidateConfirm = (data: any) => fetchApi("post", "api/employee/entry/validateConfirmEntry", data)

/**取消入职 */
const fetchOnBoardCancel = (data: any) => fetchApi("post", "api/employee/entry/waitingEntry/cancel", data)

/** 恢复入职 */
const fetchOnBoardRecovery = (data: any) => fetchApi("post", "api/employee/entry/cancelEntry/renewWaiting", data)

/** 校验恢复入职 */
const fetchOnBoardValidateRecovery = (data: any) => fetchApi("post", "api/employee/entry/validateSaveWaitingEntry", data)

/** 调整日期 */
const fetchOnBoardAdjust = (data: any) => fetchApi("post", "api/employee/entry/waitingEntry/update/enrollmentDate", data)

/** 发送入职登记表 */
const fetchOnBoardSend = (data: any) => fetchApi("post", `api/employee/entry/sendEntryRegister/${data.employeeCode}/${data.companyName}`)

/** 查询待入职统计数据 */
const fetchStatistics = (data: any) => fetchApi("get", "api/employee/entry/waitingEntry/statistics")

/**待入职导出 */
const fetchOnBoardExport = (data: any) => fetchApi("post", "api/employee/entry/waitingEntry/export", data)

/**已取消入职导出 */
const fetchCancelEntryExport = (data: any) => fetchApi("post", "api/employee/entry/cancelEntry/export", data)

/**获取导入模板下载地址 */
const fetchTemplateUrl = () => fetchApi("get", "api/employee/entry/queryImportTemplate")

/**批量导入 */
const fetchExcelImport = (data: any) => fetchApi("post", "api/employee/entry/excelImport", data)

export {
  fetchOnBoardList,
  fetchOnBoardAdd,
  fetchOnBoardValidateAdd,
  fetchOnBoardConfirm,
  fetchOnBoardValidateConfirm,
  fetchOnBoardCancel,
  fetchOnBoardRecovery,
  fetchOnBoardValidateRecovery,
  fetchOnBoardAdjust,
  fetchOnBoardSend,
  fetchStatistics,
  fetchCancelEntryList,
  fetchOnBoardExport,
  fetchCancelEntryExport,
  fetchTemplateUrl,
  fetchExcelImport
}