import fetchApi from "utils/fetchApi";

/**查询应用配置列表 */
const fetchApplicationConfigList = (data: any) => fetchApi("post", "api/cooperativeApp/cooperativeCompanyConfig/queryList", data)

/** 修改应用配置 */
const fetchApplicationConfigUpdate = (data: any) => fetchApi("post", "api/cooperativeApp/cooperativeCompanyConfig/updateConfig", data)

/**添加应用配置 */
const fetchApplicationConfigAdd = (data: any) => fetchApi("post", "api/cooperativeApp/cooperativeCompanyConfig/updateConfig", data)

export {
  fetchApplicationConfigList,
  fetchApplicationConfigUpdate,
  fetchApplicationConfigAdd
}